import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Label, Flex } from 'components/primitives'
import Divider from 'components/Divider'
import Margin from 'components/Margin'
import { PopupCard, EventPopupCard } from 'components/PopupManager'
import CallTypeIcon from 'forms/CallForm/CallTypeSelection/CallTypeIcon'
import { EventTypeToCallType } from 'utils/callstore'
import { MdAssignmentReturn } from 'react-icons/md'
import CallEvent from './CallEvent'
import CallEventSummary from './CallEventSummary'
import CallEventHeading from './CallEventHeading'
import NoHistory from './NoHistory'
import CallHistoryPopupCard from './CallHistoryPopupCard'
import { EventFeed } from 'types/events'

const CallEventList = ({ events, loading, error, children }: EventFeed & { children?: React.ReactNode }) => {
  // TODO: add error and loading. How to detect an error and loading events from the redux store?
  const { t } = useTranslation()
  const [selectedEvent, selectEvent] = React.useState<EventFeed['events'][0] | null>(null)
  const [callHistoryPopupCard, openCallHistoryPopupCard] = React.useState(false)

  return (
    <React.Fragment>
      <PopupCard
        heading={t('Call History')}
        icon={MdAssignmentReturn}
        onClick={() => {
          selectEvent(null)
          openCallHistoryPopupCard(true)
        }}
      >
        {(!events || events.length === 0) && !loading && <NoHistory />}
        {events &&
          events.map((event) => {
            const isLast = event === events[events.length - 1]
            return (
              <React.Fragment key={event.eventIdentifier}>
                <Flex key={event.eventIdentifier}>
                  {event && event.eventType && (
                    <CallTypeIcon
                      style={{ alignSelf: 'center', marginRight: 8 }}
                      callType={EventTypeToCallType[event.eventType]}
                    />
                  )}
                  <CallEventSummary
                    event={event}
                    onClick={selectEvent}
                    isActive={selectedEvent ? selectedEvent.eventIdentifier === event.eventIdentifier : false}
                  />
                </Flex>
                {!isLast && <Divider />}
              </React.Fragment>
            )
          })}
        {error && (
          <Margin spacing="sm">
            <Label>{t('Error loading history')}</Label>
          </Margin>
        )}
        {children}
      </PopupCard>
      {selectedEvent && (
        <EventPopupCard heading={<CallEventHeading event={selectedEvent} onClick={selectEvent} />}>
          <CallEvent event={selectedEvent} />
        </EventPopupCard>
      )}
      {callHistoryPopupCard && !selectedEvent && (
        <CallHistoryPopupCard events={events || []} loadingHistory={loading} errorHistory={error} />
      )}
    </React.Fragment>
  )
}

export default CallEventList
