import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { useTimer } from 'hooks/utils'

type Props = {
  timeZone: string
}

export const LocalTime = ({ timeZone }: Props) => {
  useTimer(0) // force render to keep localTime time updated
  const { t } = useTranslation()

  const localTime = DateTime.local().setZone(timeZone).toFormat('t')

  return <>{localTime + t(` - Local Time`)}</>
}

export default LocalTime
