import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MdDelete, MdDirectionsCar, MdPerson } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { updateLocation } from 'redux/location/locationSlice'
import { LocationTypeToReduxName } from 'redux/location/locationHelpers'
import CircleIcon, { CircleIconSize } from 'components/CircleIcon'
import ContextMenu from 'components/ContextMenu'
import { Pill, Row, sortMenu } from './HeroContextMenu'
import { MarkerSize } from './Markers/utils'
import { useGoogleMaps } from 'hooks/map'
import { useMarkerContainerPoint } from 'components/GoogleMaps/Marker'
import { LocationPayload, LocationType } from 'types/location'
import { useVehicleLocation, useCallerLocation } from 'hooks/redux/useLocations'
import { MarkerType } from 'types/googleMaps'
import { trackPendoVehiclePlacement } from 'utils/pendo'

type Props = {
  location: LocationPayload
  hide: () => void
}

const TARGET_OFFSET_X = MarkerSize.SMALL[0] + 10
const TARGET_OFFSET_Y = MarkerSize.SMALL[1] + 8

export const IncidentLocationMenu: React.FC<Props> = ({ location, hide }) => {
  const { t } = useTranslation()
  const { map } = useGoogleMaps()
  const callerLocation = useCallerLocation()
  const vehicleLocation = useVehicleLocation()
  const containerPoint = useMarkerContainerPoint()
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (map) {
      const contextListener = map.addListener('contextmenu', hide)
      const clickListener = map.addListener('click', hide)
      const moveListener = map.addListener('dragstart', hide)
      return () => {
        google.maps.event.removeListener(contextListener)
        google.maps.event.removeListener(clickListener)
        google.maps.event.removeListener(moveListener)
      }
    }
  }, [hide, map])

  const handleUpdateLocation = (locationType: LocationType | string) => () => {
    // Handle Pin Removal
    if (LocationTypeToReduxName[locationType] === undefined) {
      dispatch(
        updateLocation({
          name: LocationTypeToReduxName[LocationType[location.serviceLocationType]],
          location: null,
        }),
      )
    }

    // Handle pin "Swap"
    if (locationType === LocationType.VEHICLE && callerLocation) {
      dispatch(
        updateLocation({
          name: 'vehicleLocation',
          location: { ...location, serviceLocationType: MarkerType.VEHICLE },
        }),
      )
      trackPendoVehiclePlacement()
      dispatch(updateLocation({ name: 'callerLocation', location: null }))
    } else if (locationType === LocationType.CALLER && vehicleLocation) {
      dispatch(
        updateLocation({
          name: 'callerLocation',
          location: { ...location, serviceLocationType: MarkerType.CALLER },
        }),
      )
      dispatch(updateLocation({ name: 'vehicleLocation', location: null }))
    }

    hide()
  }

  let address: string | null = null
  if (location) {
    const lat = location.latitude
    const lon = location.longitude
    const addr = location.addressDescription
    if (addr) address = addr.split('<br/>')[0]
    else if (lat && lon) address = `${lat}:${lon}`
  }

  const items = [
    [<div style={{ width: 36, height: 36 }} />, address && <Pill>{address}</Pill>, hide, 'flex-start', null],
    [
      <CircleIcon icon={MdPerson} circleSize={CircleIconSize.MEDIUM} />,
      <Pill>{t('menu.caller')}</Pill>,
      handleUpdateLocation(LocationType.CALLER),
      'flex-start',
      LocationType.CALLER,
    ],
    [
      <CircleIcon icon={MdDirectionsCar} circleSize={CircleIconSize.MEDIUM} />,
      <Pill>{t('menu.vehicle')}</Pill>,
      handleUpdateLocation(LocationType.VEHICLE),
      'flex-start',
      LocationType.VEHICLE,
    ],
    [
      <CircleIcon icon={MdDelete} circleSize={CircleIconSize.MEDIUM} />,
      <Pill>{t('menu.remove')}</Pill>,
      handleUpdateLocation(location.serviceLocationType),
      'flex-start',
      null,
    ],
  ]

  if (!containerPoint) return null

  return (
    <ContextMenu containerPoint={containerPoint} xOffset={TARGET_OFFSET_X} yOffset={TARGET_OFFSET_Y} topMargin={36}>
      {(anchors) => {
        const sortedMenu = sortMenu(
          items.filter((item) => item[4] !== LocationType[location.serviceLocationType]),
          anchors,
        )
        return sortedMenu.map(([first, second, onClick, justifyContent], i) => {
          return (
            <Row
              key={i}
              style={{
                justifyContent: justifyContent || 'flex-start',
              }}
              onClick={onClick}
            >
              {first}
              <div style={{ padding: '8px' }} />
              {second}
            </Row>
          )
        })
      }}
    </ContextMenu>
  )
}

export default IncidentLocationMenu
