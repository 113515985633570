import { CountryCode, isSupportedCountry, parsePhoneNumber } from 'libphonenumber-js'
import { isNil } from 'lodash'
import { Maybe, SelectOption } from 'types/global'

export const applyMask = (pattern: string, value: string) => {
  let i = 0
  const v = value.toString()
  return `${pattern.replace(/x/g, (_) => v[i++] || '')}`
}

export const formatPhone = (number: Maybe<string>, countryCode?: SelectOption<any>) => {
  if (isNil(number) || isNil(countryCode) || !countryCode.value) return null

  try {
    let defaultCountry = countryCode.value.toUpperCase() as CountryCode
    if (!isSupportedCountry(defaultCountry)) return number
    const defaultPhone = parsePhoneNumber(number, defaultCountry)

    const formatted = `${defaultPhone.formatNational()}`
    return formatted
  } catch (e) {
    console.error(e)
    return number
  }
}

export const parsePhone = (number: Maybe<string>, countryCode: SelectOption<any>) => {
  if (isNil(number) || isNil(countryCode) || !countryCode.value) return null
  return {
    number: number.replace(/\D/g, ''), // remove all formatting
    countryCode: countryCode.value,
  }
}
