import * as React from 'react'
import CallEventList from './CallEventList'
import { useSelector } from 'react-redux'
import { selectTicketHistoryEvents } from 'redux/appStore'
import ErrorBoundary from '../../../../../../components/ErrorBoundary'

const CallHistory: React.FC = () => {
  const callHistoryEvents = useSelector(selectTicketHistoryEvents) || []
  // TODO: determine if the events are loading or have an error

  return (
    <React.Fragment>
      <ErrorBoundary>
        <CallEventList events={callHistoryEvents} loading={false}>
          {/* {loading && (
            <Margin spacing="sm">
              <Loading />
            </Margin>
          )} */}
        </CallEventList>
      </ErrorBoundary>
    </React.Fragment>
  )
}

export default CallHistory
