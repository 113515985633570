import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Heading } from 'components/primitives'
import { millisecondsToTime } from 'hooks/utils/useTimer'

export const CallerFormTimer: React.FC<{ time: number }> = ({ time }) => {
  const { t } = useTranslation()
  const timeElapsed = millisecondsToTime(time)

  return (
    <Heading as="h5">
      {t('New Call')} ({timeElapsed})
    </Heading>
  )
}
