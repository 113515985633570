import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Heading, Paragraph, Flex } from 'components/primitives'
import { MdPlace, MdToday, MdAccessTime, MdPhone } from 'react-icons/md'
import { TransformedPickupReturnInfo } from 'types/ticket'
import { ListItem } from 'components/List'
import { PopupCard } from 'components/PopupManager'
import Margin from 'components/Margin'
import LocalTime from 'components/LocalTime'
import useLocalTimeZone from 'hooks/utils/useLocalTimeZone'
import { pxToRem } from 'theme/utils'
import BranchHoursTable from './BranchHoursTable'
import useBranchHours from 'hooks/solr/useBranchHours'
import { BranchInfoFragment } from 'types/branch'

const BranchDetails: React.FC<TransformedPickupReturnInfo & { heading: string }> = ({ heading, branch, dateTime }) => {
  const { t } = useTranslation()
  let date: any = new Date()
  let startDateForBranchHours = date.toISOString().split('T')
  startDateForBranchHours = startDateForBranchHours[0]
  let endDateForBranchHours: any = new Date(new Date().setDate(new Date().getDate() + 6)).toISOString().split('T')
  endDateForBranchHours = endDateForBranchHours[0]

  const branchLocation = branch as BranchInfoFragment

  const { data: branchHourData } = useBranchHours(branchLocation.peopleSoftId, startDateForBranchHours, endDateForBranchHours)

  if (!branch) return null
  const {
    brand,
    defaultLocationName,
    formattedPhone,
    addressLines = [],
    formattedCityStateZip,
    latitude,
    longitude,
    groupBranchNumber,
  } = branchLocation
  return (
    <PopupCard heading={heading}>
      <Heading as="h5">{brand}</Heading>
      <Paragraph spacing="none">{defaultLocationName}</Paragraph>
      <Paragraph spacing="md">{groupBranchNumber}</Paragraph>
      <Margin use={ListItem} spacing="md">
        <ListItem.Icon icon={MdPhone} color="elm" />
        <ListItem.Text>{formattedPhone}</ListItem.Text>
      </Margin>
      <Margin use={ListItem} spacing="md">
        <ListItem.Icon icon={MdToday} color="elm" />
        <ListItem.Text>
          {t('date.standard', {
            date: new Date(dateTime),
          })}
        </ListItem.Text>
      </Margin>
      <Margin use={ListItem} spacing="md">
        <ListItem.Icon icon={MdPlace} color="elm" />
        <ListItem.Text>
          {addressLines && addressLines[0]} <br />
          {formattedCityStateZip}
        </ListItem.Text>
      </Margin>
      {branchHourData && (
        <Margin spacing="md">
          <Flex style={{ alignItems: 'center', margin: `0 0 ${pxToRem(8)}` }}>
            <ListItem.Text>{t('date.businessHours')}</ListItem.Text>
          </Flex>
          <BranchHoursTable hours={branchHourData} />
        </Margin>
      )}
      <ListItem>
        <ListItem.Icon icon={MdAccessTime} color="elm" />
        <BranchLocalTime lat={latitude} lon={longitude} />
      </ListItem>
    </PopupCard>
  )
}

const BranchLocalTime: React.FC<{ lat: string; lon: string }> = (props) => {
  const { timezone } = useLocalTimeZone(props)
  return timezone ? <LocalTime timeZone={timezone} /> : null
}

export default BranchDetails
