import * as React from 'react'
import * as Ariakit from '@ariakit/react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-final-form'
import { ServiceLocationType } from 'types/location'
import useLocations from 'hooks/redux/useLocations'
import { Condition } from 'components/fields'
import { Heading } from 'components/primitives'
import Margin from 'components/Margin'
import SmartAssignment from 'components/SmartAssignment'
import Exchange from 'components/Exchange'
import { Step, BackNavigation } from 'components/Step'
import DispatchTow from './DispatchTow'
import { VehicleSelect } from 'components/Select'
import Location from 'components/Input/Location'
import { TowExchange } from 'types/callstore'
import VehicleCondition from 'components/VehicleCondition'
import { isRequired } from 'utils/validators'
import Input from 'components/Input'
import { CustomerDropOffDestination } from '../../NoExchange/Steps'
import {
  setSmartAssignmentCustomDropoffDestination,
  setSmartAssignmentAssignment,
} from 'redux/smartAssignment/smartAssignmentSlice'
import { useDispatch, useSelector } from 'react-redux'
import { selectCustomVinInput, selectSmartAssignment } from 'redux/appStore'
import { Branch } from 'types/branch'

// 0 - Vehicle
// 1 - Vehicle Destination

const DirectionVehicle: React.FC = () => {
  const { t } = useTranslation()
  const form = useForm()
  const controlsContext = Ariakit.useCompositeContext()
  const { assignment } = useSelector(selectSmartAssignment)
  const dispatch = useDispatch()
  const locations = useLocations()
  const customVinInput = useSelector(selectCustomVinInput)
  const { callerLocation, vehicleLocation } = locations

  return (
    <>
      <>
        <Step step="1">
          {!assignment && (
            <>
              <BackNavigation
                onClick={() => {
                  form.change('serviceLocations', locations)
                  form.change('towDirection', null)
                  form.change('rideAlong', null)
                  form.change('customerSupplied', null)
                }}
              />
              <Margin spacing="md">
                <Heading as="h5" spacing="md">
                  {t('Disabled Vehicle Destination')}
                </Heading>
                <VehicleCondition />
                <VehicleSelect label={t('labels.requiredVehicle')} name="vehicle" />
                <Input.Hidden name="customVin" defaultValue={customVinInput || undefined} />
                {!customVinInput ? <Input.Hidden name="vehicle.value" validate={isRequired(t('required.vehicle'))} /> : null}
                <Margin spacing="md">
                  <Location.Vehicle name="serviceLocations.vehicleLocation" />
                </Margin>
              </Margin>
            </>
          )}
          <Condition when="vehicleCondition" is={(v) => !!v}>
            <SmartAssignment
              onSetAssignment={(participant) => {
                form.change('serviceLocations.vehicleDestinationLocation', {
                  ...participant,
                  serviceLocationType: ServiceLocationType.VEHICLEDESTINATION,
                })
                controlsContext?.move(controlsContext?.next())
              }}
            />
          </Condition>
        </Step>
        <Step step="2">
          <BackNavigation
            onClick={() => {
              dispatch(setSmartAssignmentAssignment(null))
              dispatch(setSmartAssignmentCustomDropoffDestination(null))
              form.change('branch', null)
              controlsContext?.move(controlsContext?.previous())
            }}
          />
          <Heading as="h5" spacing="md">
            {t('Exchange Branch')}
          </Heading>
          <Exchange
            name="branch"
            searchLocation={callerLocation || vehicleLocation}
            isTowExchange={true}
            onSetBranch={(branch) => {
              const { rideAlong } = form.getState().values as TowExchange
              const exchangeBranch = branch as Branch
              if (rideAlong) {
                const { brand } = exchangeBranch
                const addressLines = exchangeBranch?.address?.street_addresses[0]
                const formattedCityStateZip = exchangeBranch?.additional_data?.formatted_city_state_zip
                form.change('rideAlongLocation', `${brand}, ${addressLines}, ${formattedCityStateZip}`)
              }
              controlsContext?.move(controlsContext?.next())
            }}
          />
        </Step>
        <Step step="3">
          <CustomerDropOffDestination />
        </Step>
        <Step step="4">
          <BackNavigation
            onClick={() => {
              const { rideAlong } = form.getState().values as TowExchange
              if (rideAlong) {
                form.change('rideAlongLocation', null)
              }
              controlsContext?.move(controlsContext?.previous())
            }}
          />
          <DispatchTow />
        </Step>
      </>
    </>
  )
}

export default DirectionVehicle
