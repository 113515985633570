import { Participant, Branch } from 'types/global'
import { LocationPayload } from 'types/location'

//  Google Maps Based Locations - Vehicle / Customer / Custom
export function isLocation(location: any): location is LocationPayload {
  return location && 'latitude' in location && 'longitude' in location
}

// Exchange Branch
export function isBranch(location: any): location is Branch {
  return location && 'gps' in location
}

// "Network" Locations Body Shop / Service Center / Tire Shop
export function isParticipant(location: any): location is Participant {
  return location && ('entity' in location || ('entity' in location && 'location' in location))
}

export const isNumeric = (num: any): num is number =>
  (typeof num === 'number' || (typeof num === 'string' && num.trim() !== '')) && !isNaN(num as number)
