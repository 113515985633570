import { useServiceProxy } from 'hooks/kong'
import useLocations from 'hooks/redux/useLocations'
import { useDispatch } from 'react-redux'
import { DynamoEvents } from 'types/events'
import { updateTicketHistoryEvent } from 'redux/callHistory/callHistorySlice'

export const useUpdateEvent = () => {
  const serviceProxy = useServiceProxy()
  const dispatch = useDispatch()
  const serviceLocations = useLocations()
  return async (event: DynamoEvents) => {
    // Just take rentalContext + vehicleContext of the previously created event instead of generating our own everytime
    const { audit, eventIdentifier, eventType, rentalContext, vehicleContext, ...data } = event

    const body = {
      audit,
      eventIdentifier,
      eventType,
      rentalContext,
      vehicleContext,
      serviceLocations,
      timestamp: new Date().toISOString(),
      attributes: { ...data.attributes },
    }

    const updatedServiceEvent = await serviceProxy<DynamoEvents>('PUT', '/serviceproxy/events-table/items', {}, body)
    if (updatedServiceEvent.data) {
      dispatch(updateTicketHistoryEvent({ event: updatedServiceEvent.data }))
    }
    return updatedServiceEvent
  }
}

export default useUpdateEvent
