import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Label, Paragraph } from 'components/primitives'
import { YesNoTriggers } from 'components/Input'
import SoftServiceEvent from '../SoftServiceEvent'
import { DynamoEvents } from 'types/events'
import { createCancelDialogContext } from 'components/Dispatch/Cancel/useCancelServiceDialog'

const CapitalizedParagraph = styled(Paragraph as any)`
  text-transform: capitalize;
`
export const cancelDialogContext = createCancelDialogContext()

function LockoutEvent(event: DynamoEvents) {
  const { t } = useTranslation()
  const { exchange, lostKeys, keysLocation, trunkAccess, personalBelongings } = event?.attributes

  return (
    <cancelDialogContext.Provider>
      <SoftServiceEvent title={t('lockout.heading')} {...event}>
        <Label>{t('lockout.keys.lost')}</Label>
        <CapitalizedParagraph spacing="sm">{lostKeys}</CapitalizedParagraph>
        {lostKeys === YesNoTriggers.YES ? (
          <>
            <Label>{t('lockout.belongings')}</Label>
            <CapitalizedParagraph spacing="sm">{personalBelongings}</CapitalizedParagraph>
          </>
        ) : (
          <>
            <Label>{t('lockout.keys.location')}</Label>
            <CapitalizedParagraph spacing="sm">{keysLocation}</CapitalizedParagraph>
            {keysLocation === 'trunk' && (
              <>
                <Label>{t('lockout.trunk.hasAccess')}</Label>
                <CapitalizedParagraph spacing="sm">{trunkAccess}</CapitalizedParagraph>
                {trunkAccess === YesNoTriggers.NO && (
                  <>
                    <Label>{t('lockout.belongings')}</Label>
                    <CapitalizedParagraph spacing="sm">{personalBelongings}</CapitalizedParagraph>
                  </>
                )}
              </>
            )}
          </>
        )}
        {exchange && (
          <>
            <Label>{t('labels.dispatchServices')}</Label>
            <Paragraph spacing="sm">{t('labels.exchange')}</Paragraph>
          </>
        )}
      </SoftServiceEvent>
    </cancelDialogContext.Provider>
  )
}

export default LockoutEvent
