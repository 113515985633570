import * as React from 'react'
// import LawEvent from 'forms/CallForm/LawEnforcement/LawEvent'
import RecoveryEvent from 'forms/CallForm/VehicleRecovery/RecoveryEvent'
import WinchEvent from 'forms/CallForm/Winch/WinchEvent'
import { LockoutEvent } from 'forms/CallForm/LockoutForm'
import ExchangeEvent from 'forms/CallForm/ExchangeForm/ExchangeEvent'
import FuelEvent from 'forms/CallForm/FuelService/FuelEvent'
import LDREvent from 'forms/CallForm/LDR/LDREvent'
import JumpstartEvent from 'forms/CallForm/JumpstartService/JumpstartEvent'
import TireEvent from 'forms/CallForm/TireRepair/TireEvent'
import TireChangeEvent from 'forms/CallForm/TireRepair/TireChangeEvent'
import AccidentEvent from 'forms/CallForm/Accident/AccidentEvent'
import { TowEvent } from 'forms/CallForm/TowForm'
import CallerDetails from './CallerDetails'
import { EventFeed } from 'types/events'
import EscalateEvent from 'forms/CallForm/EscalateForm/EscalateEvent'
import { useContactsRefresh } from 'hooks/tickets'
import { DynamoContacts } from 'types/contacts'
import Divider from '../../../../../../components/Divider'
import { useEffect } from 'react'
import { isNil } from 'lodash-es'

export const callEvents = {
  accident: AccidentEvent,
  // law: LawEvent, Currently No Law workflow - Hiding for now
  recovery: RecoveryEvent,
  exchange: ExchangeEvent,
  escalate: EscalateEvent,
  ldr: LDREvent,
  fuel: FuelEvent,
  lockout: LockoutEvent,
  jumpstart: JumpstartEvent,
  winch: WinchEvent,
  tiresChangeSpare: TireChangeEvent,
  tires: TireEvent,
  tow: TowEvent,
}

const CallEvent: React.FC<{ event: EventFeed['events'][0] }> = ({ event }) => {
  const [caller, setCaller] = React.useState<DynamoContacts | null>(null)
  const contactsRefresh = useContactsRefresh()
  useEffect(() => {
    if (isNil(caller)) {
      contactsRefresh(event.eventIdentifier).then((response) => {
        setCaller(response.data.Items[0])
      })
    }
  }, [event, contactsRefresh, caller])
  const Component = callEvents[event.eventType as keyof typeof callEvents] || ''
  return (
    <>
      {<CallerDetails event={event} caller={caller} />}
      <Divider />
      {Component && <Component {...(event as any)} readOnly />}
    </>
  )
}

export default CallEvent
