import * as React from 'react'
import { Branch, BranchInfoFragment } from 'types/global'
import useExchangeSearch from './useExchangeSearch'
import { useState } from 'react'
import ExchangeCarousel from './ExchangeCarousel'
import { Heading, Paragraph } from '../primitives'
import MdSpinner from 'react-md-spinner'
import { calypso } from '../../theme/colors'
import { pxToRem } from '../../theme/utils'
import Column from '../Column'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import Margin from 'components/Margin'
import Location from 'components/Input/Location'
import { useCallerLocation } from 'hooks/redux/useLocations'
import { LocationPayload } from 'types/location'
import { isNil } from 'lodash-es'

type Props = {
  name: string
  searchLocation?: LocationPayload | null
  onSetBranch?: (branch: Branch | BranchInfoFragment) => void
  isTowExchange: boolean
}

const ExchangeSearch = ({ name, searchLocation, onSetBranch, isTowExchange }: Props) => {
  const { fetch, data, loading } = useExchangeSearch(searchLocation as LocationPayload)
  const [lastLocation, setLastLocation] = useState({} as LocationPayload)
  const { t } = useTranslation()
  const [confirmLocation, setConfirmLocation] = React.useState<boolean>(false)
  const callerLocation = useCallerLocation()

  React.useEffect(() => {
    if (lastLocation !== searchLocation && !loading && searchLocation && confirmLocation) {
      fetch().then(() => {
        setLastLocation(searchLocation)
      })
    }
  }, [searchLocation, lastLocation, confirmLocation])

  const refresh = () => {
    fetch()
  }

  if (isNil(data) && confirmLocation && !loading) {
    return (
      <Column>
        <Margin spacing="md">
          <Paragraph as="h5" style={{ marginTop: 12 }}>
            {t('No Results found.')}
          </Paragraph>
          <Paragraph as="h5" style={{ marginTop: 12 }}>
            <Button.Primary onClick={() => refresh()} style={{ width: '100%' }}>
              {t('retrySearch')}
            </Button.Primary>
          </Paragraph>
        </Margin>
      </Column>
    )
  } else if (confirmLocation && loading) {
    return (
      <Column>
        <Heading as="h4">{t('Searching for closest branch')}</Heading>
        <MdSpinner singleColor={calypso} size={64} style={{ margin: `${pxToRem(32)} 0` }} />
      </Column>
    )
  } else if (!isNil(data) && confirmLocation && !loading) {
    return (
      <ExchangeCarousel
        name={name}
        items={data.exchangeSearch.carClassAvailability}
        isTowExchange={isTowExchange}
        onSetBranch={onSetBranch}
        setConfirmLocation={setConfirmLocation}
      />
    )
  }

  return (
    <>
      <Heading as="h5" spacing="md">
        Confirm Location
      </Heading>
      <Margin spacing="md">
        {callerLocation ? <Location.Caller name="callerLocation" /> : <Location.Vehicle name="vehicleLocation" />}
      </Margin>
      <Button.Primary
        disabled={!searchLocation}
        onClick={() => {
          setConfirmLocation(true)
        }}
        style={{ width: '100%' }}
      >
        {t('labels.confirmLocation')}
      </Button.Primary>
    </>
  )
}

export default ExchangeSearch
