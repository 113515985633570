import * as React from 'react'
import { omit } from 'lodash-es'
import { useSelector } from 'react-redux'
import { selectCustomVinInput, selectTicket } from 'redux/appStore'
import { InputProps } from 'components/Input'
import { Select } from 'components/Input/FinalFormInputs'
import { TransformedVehicle } from 'types/ticket'
import { Paragraph } from 'components/primitives'

export const VehicleSelect: React.FC<InputProps> = (props) => {
  const { vehicle, vehicleHistory = [] } = useSelector(selectTicket)
  const customVinInput = useSelector(selectCustomVinInput)

  const vehicles: (TransformedVehicle | null)[] = [vehicle, ...vehicleHistory].filter(Boolean)
  const options = vehicles.map((vehicle) => {
    const { year, make, model, licensePlate, vinLast8 } = vehicle as TransformedVehicle
    return {
      ...omit(vehicle, 'equipmentInfo'),
      label: `${year || ''} ${make} ${model} (${licensePlate})`,
      value: vinLast8,
    }
  })

  return customVinInput ? (
    <Paragraph style={{ marginBottom: 12 }}>Vehicle VIN: {customVinInput}</Paragraph>
  ) : (
    <Select
      {...props}
      placeholder="--"
      isClearable
      options={options}
      defaultValue={options.length === 1 ? (options[0] as any) : undefined}
    />
  )
}

export default VehicleSelect
