import * as React from 'react'
import { useForm } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import { Error } from 'components/fields'
import Margin from 'components/Margin'
import { Law, Officer } from 'types/callstore'
import { formatPhone, parsePhone } from 'utils/formatPhone'
import { useSelector } from 'react-redux'
import { selectFormsState } from 'redux/appStore'

export type SubmitParams = {
  callerName: string
  phone: string
  officer: Officer
  law: Law
}

type SubmitProps = {
  onSubmit: (params: SubmitParams) => Promise<void>
  children: React.ReactNode
}

const LawSubmit: React.FC<SubmitProps> = ({ onSubmit, children }) => {
  const form = useForm()
  const forms = useSelector(selectFormsState)
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string | null>(null)

  return (
    <>
      {error && (
        <Margin spacing="md">
          <Error.Message>{error}</Error.Message>
        </Margin>
      )}
      <Button.Primary
        loading={loading}
        onClick={async () => {
          setError(null)

          // Validate caller, officer, and form state
          const callerForm = forms.caller
          const officerForm = forms.officer

          let officerValid: boolean
          if (officerForm) {
            await officerForm.submit() // trigger any errors in officer form
            officerValid = officerForm.getState().valid
          } else {
            // officer form doesn't exist
            setError(t('law.officerRequired') as any)
            return
          }

          await callerForm.submit() // trigger any errors in caller form
          await form.submit() // trigger any errors in current form

          const callerState = callerForm.getState()
          const state = form.getState()

          if (!callerState.valid || !officerValid || !state.valid) {
            return
          }

          setLoading(true)

          const law = state.values as Law
          const officer = officerForm.getState().values as Officer

          const caller = callerState.values
          const parsedPhone = parsePhone(caller.phone.number, caller.phone.countryCode.value)
          const phone = formatPhone(parsedPhone?.number || '', parsedPhone?.countryCode)!

          await onSubmit({ law, officer, phone, callerName: caller.callerName })
          setLoading(false)
        }}
      >
        {children}
      </Button.Primary>
    </>
  )
}

export default LawSubmit
