import { alpha3ToAlpha2 } from 'i18n-iso-countries'
import { alpha3Options } from 'components/Select/CountrySelect'
import { findCountryOption } from 'components/Select/CountryCodeSelect'
import { Driver, TransformedDriver, MultiRetrieveResponse, TransformedTicket, Vehicle, DriverPhone } from 'types/ticket'
import { Branch } from 'types/global'
import { isNil, pick } from 'lodash-es'
import {
  parsePhoneNumberFromString,
  parsePhoneNumberWithError,
  CountryCode,
  isSupportedCountry,
  ParseError,
} from 'libphonenumber-js'
import { LDFlagSet } from 'launchdarkly-js-client-sdk'

function sanitizePrimaryDriver(primaryDriver: Driver) {
  if (isNil(primaryDriver) || Object.values(primaryDriver).every((prop) => !prop)) return defaultDriver
  return primaryDriver
}

export const composeSearchTerms = (terms: object) =>
  Object.entries(terms)
    .map(([key, value]) => {
      if (value !== undefined) {
        // Handle firstName / lastName searches with a Space in the name
        if (key.includes('Name') && value.includes(' ')) return `${key}:\"${value}\"`
        return `${key}:${value}`
      }
    })
    .join(' ')

const transformVehicle = (vehicle: Vehicle) => {
  if (!vehicle) return null

  // TODO: Remove this - TestData
  return {
    ...vehicle,

    // make: '',
    // model: '',
    // year: null,
    // color: '',
    // vin: '',
    // sippCode: null,
    // vehicleAssignedDateTime: null,
    // series: null,
    // vehicleUnassignedDateTime: null,
    // endOdometer: null,
    // startOdometer: null,
    // lastRecordedOdometer: null,
    // nextPmOdometer: null,
    // unitNumber: null,
    // installedDateTime: null,
    // equipmentInfo: [],
    vinLast8: vehicle.vin && vehicle.vin.slice(-8),
    licensePlate: vehicle.licensePlate ? vehicle.licensePlate.number : null,
    licensePlateState: vehicle.licensePlate ? vehicle.licensePlate.countrySubdivision : null,
  }
}

const transformPhone = (phone: (DriverPhone | undefined)[]): DriverPhone[] => {
  if (isNil(phone)) return []

  const mappedPhone = phone.map((pn) => {
    if (isNil(pn))
      return {
        number: '',
        type: 1,
        countryCode: { label: '+1 United States', value: 'USA' },
        countryOption: findCountryOption('US'),
      }

    const alpha2Code = alpha3ToAlpha2(pn.countryCode.value) || 'US'
    const countryOption = findCountryOption(alpha2Code)

    return { ...pn, countryOption }
  })

  return mappedPhone
}

const transformDriver = (driver: Driver | null): TransformedDriver | null => {
  if (!driver) return null
  try {
    const address = driver.address || []
    const phone = transformPhone((driver as Driver).phone)

    return {
      ...driver,
      fullName: [driver.firstName, driver.lastName].filter(Boolean).join(' '),
      address: address.map((addr) => ({
        ...addr,
        countryOption: alpha3Options.find((c) => c.value === addr.countryCode),
      })),
      phone: phone,
    }
  } catch (e) {
    console.error('Error transforming driver')
    console.error(e)
  }
  return null
}

function formatPhoneNumber(primaryDriver: Driver): Driver {
  const defaultPhone = { number: '', countryCode: { label: '+1 United States', value: 'USA' }, countryOption: null, type: 1 }

  if (primaryDriver.phone.length === 0 || !primaryDriver.phone[0]) {
    primaryDriver.phone.push(defaultPhone)
    return primaryDriver
  }
  // Number shouldn't be null but in case there is no "phone" being returned, default to empty string
  const phoneNumber = primaryDriver.phone[0].number || defaultPhone.number
  const countryCode = primaryDriver.phone[0].countryCode || defaultPhone.countryCode // countryCode can be null. Default to USA
  // Alpha3ToAlpha2 codes: https://github.com/michaelwittig/node-i18n-iso-countries/blob/master/codes.json
  const alpha2 = (alpha3ToAlpha2(countryCode.value) || 'us') as CountryCode // Default to us again

  // If the library doesn't support the country code, just input the default.
  if (!isSupportedCountry(alpha2)) {
    primaryDriver.phone[0] = defaultPhone
    return primaryDriver
  }

  const formatted = parsePhoneNumberWithError(phoneNumber, alpha2)
  primaryDriver.phone[0].number = formatted.formatNational()
  return primaryDriver
}

export function transformMultiRetrieveTicket(
  ticket: MultiRetrieveResponse,
  solr: { branches: Branch[] },
  ldConfig: LDFlagSet,
): TransformedTicket {
  // LD Config & Null
  const { roadsideIsNullDriver } = ldConfig
  const isNullDriver = isNil(ticket.driver.primaryDriver) && roadsideIsNullDriver
  // Driver Information
  const primaryDriver = isNullDriver ? defaultDriver : sanitizePrimaryDriver(ticket.driver.primaryDriver)
  const additionalDrivers = ticket.driver.additionalDriver.filter((driver) => !Object.values(driver).every((v) => isNil(v)))
  const allDrivers = [primaryDriver, ...additionalDrivers].map(transformDriver).filter(Boolean)
  const formattedPrimaryDriver = formatPhoneNumber(primaryDriver)
  const transformedDriver = transformDriver(formattedPrimaryDriver)

  // Vehicle Information
  const vehicle = transformVehicle(ticket.vehicle.currentVehicle)
  const vehicleHistory = Array.isArray(ticket.vehicle.vehicleHistory) ? ticket.vehicle.vehicleHistory.map(transformVehicle) : []

  // Branch information
  const returnBranch = solr.branches[1] ? solr.branches[1] : solr.branches[0]
  const pickupInfo = {
    ...pick(ticket.pickupInfo, ['dateTime', 'method']),
    groupBranchId: ticket.pickupInfo.location.groupBranchId,
    branch: { ...solr.branches[0] },
  }
  const returnInfo = {
    ...pick(ticket.returnInfo, ['dateTime', 'method']),
    groupBranchId: ticket.returnInfo.location.groupBranchId,
    branch: {
      ...returnBranch,
    },
  }

  return {
    ...ticket,
    primaryDriver: transformedDriver,
    additionalDriver: additionalDrivers,
    drivers: allDrivers,
    vehicle,
    vehicleHistory,
    pickupInfo,
    returnInfo,
  }
}

export function parsePhoneNumber(text: string, country: CountryCode) {
  try {
    const phoneNumber = parsePhoneNumberFromString(text, country)
    return phoneNumber
  } catch (error) {
    if (error instanceof ParseError) {
      // Not a phone number, non-existent country, etc.
      console.log(error.message)
    } else {
      throw error
    }
  }
  return undefined
}

const defaultDriver: Driver = {
  individualId: '12345',
  address: [
    {
      city: 'St. Louis',
      countryCode: 'USA',
      countryDescription: '',
      countrySubdivision: '',
      country: 'USA',
      line1: '211 North Broadway',
      line2: '',
      line3: '',
      postalCode: '63102',
      type: 1,
    },
  ],
  dateOfBirth: '07/01/2024',
  dayOfBirth: '01',
  monthOfBirth: '07',
  firstName: 'Driver Is Null',
  lastName: 'From API',
  salutation: '',
  insuranceInfo: {
    carrierName: '',
    policyIdentifier: '',
  },
  legalId: [],
  phone: [
    {
      countryCode: { label: '+1 United States', value: 'USA' },
      number: '1234567890',
      countryOption: null,
      type: 1,
    },
  ],
  suffix: '',
  emailAddress: '',
}
