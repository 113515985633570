import React, { useState, ChangeEvent } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { Input, Label } from 'components/primitives'
import useMount from 'hooks/utils/useMount'

type Props = React.ComponentProps<typeof Input> & FieldRenderProps<HTMLInputElement> & Partial<{ maxLength: string | number }>

const Textarea = ({ input, meta, maxLength, ...rest }: Props) => {
  const [currentCharacterLength, setCurrentCharacterLength] = useState(0)

  useMount(() => {
    if (input.value) setCurrentCharacterLength((input.value as any).length)
  })

  const onInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget && e.currentTarget.value) {
      setCurrentCharacterLength(e.currentTarget.value.length)
    } else {
      setCurrentCharacterLength(0)
    }
  }

  const maxLengthProps = maxLength && { onInput, maxLength }

  return (
    <div style={{ position: 'relative', paddingBottom: 8 }}>
      <Input {...input} {...rest} {...maxLengthProps} as="textarea" style={{ resize: 'vertical', minHeight: '4em' }} />
      {maxLength && (
        <Label
          style={{
            position: 'absolute',
            right: 0,
            padding: '4px 0',
          }}
        >
          {`${currentCharacterLength}/${maxLength}`}
        </Label>
      )}
    </div>
  )
}

export default Textarea
