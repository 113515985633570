import isArray from 'lodash-es/isArray'
import { FormStore } from 'types/form'
import { callerDefault, LdrClaim, officerDefault } from 'types/callstore'

export const defaultState: FormStore = {
  caller: callerDefault,
  notes: null,
  callType: null,
  ldr: {} as LdrClaim,
  workflow: [],
  officer: officerDefault,
}

export const handleArrayCheck = (a: any, b: any) => (isArray(b) ? b : undefined)
