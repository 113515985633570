import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { MarkerType } from 'types/googleMaps'
import { useSelector } from 'react-redux'
import { SelectableGoogleHeroMarker } from '../Markers/GoogleMapMarkers'
import { Tooltip } from 'components/Tooltip'
import HotelTooltip from '../Markers/Tooltips/Hotel'
import { selectMapViewport, selectTicket } from 'redux/appStore'
import { useGoogleMapsNearbySearch } from 'hooks/map/useGoogleMapsNearbySearch'
import { vehicleExchangeRadiusInKM } from 'components/Exchange/utils'

type Props = {
  markerType: MarkerType
}

export const HotelLinks = ({ markerType }: Props) => {
  const { ticketNumber } = useSelector(selectTicket)
  const navigate = useNavigate()
  const viewport = useSelector(selectMapViewport)
  const lat = (typeof viewport.center.lat === 'number' ? viewport.center.lat : viewport.center.lat()) as unknown as number
  const lng = (typeof viewport.center.lng === 'number' ? viewport.center.lng : viewport.center.lng()) as unknown as number
  const radius = vehicleExchangeRadiusInKM * 250

  const { data, loading, error } = useGoogleMapsNearbySearch(lat, lng, radius, 'lodging')

  if (!data || loading || error) return null

  return (
    <>
      {data
        .filter((hotel) => hotel.business_status === 'OPERATIONAL')
        .map((hotel) => {
          const { place_id, name, vicinity, geometry } = hotel
          const { lat, lng } = geometry.location

          return (
            <SelectableGoogleHeroMarker
              key={place_id}
              id={place_id}
              type={markerType}
              latitude={Number(lat)}
              longitude={Number(lng)}
              onClick={(selected) => {
                if (selected) {
                  navigate(`/ticket/${ticketNumber}/place/${markerType}`)
                } else {
                  navigate(`/ticket/${ticketNumber}/place/${markerType}/${place_id}`)
                }
              }}
            >
              <Tooltip>
                <HotelTooltip markerType={markerType} title={name || ''} placeId={place_id} address={vicinity} />
              </Tooltip>
            </SelectableGoogleHeroMarker>
          )
        })}
    </>
  )
}

export default HotelLinks
