import * as React from 'react'
import * as Yup from 'yup'
import { Heading } from 'components/primitives'
import { useTranslation } from 'react-i18next'
import { FormApi, FormState } from 'final-form'
import { EventTypes } from 'types/events'
import { Condition, Submit, Checkbox } from 'components/fields'
import { FormattedCaller } from 'components/fields/Submit'
import Input from 'components/Input'
import { CarKeySelect, VehicleSelect, RecoveryReasonSelect } from 'components/Select'
import Margin from 'components/Margin'
import Location from 'components/Input/Location'
import { useReduxCallStoreActions, useCreateServiceEvent } from 'hooks/events'
import useNearestBranch from 'hooks/map/useNearestBranch'
import { CallTypes, Recovery } from 'types/callstore'
import { BranchInfoFragment, SelectOption } from 'types/global'
import { Form } from 'components/FormManager'
import Divider from 'components/Divider'
import Button from 'components/Button'
import RecoveryEvent from './RecoveryEvent'
import { isRequired } from 'utils/validators'
import { useDispatch, useSelector } from 'react-redux'
import { LocationPayload } from 'types/location'
import {
  ReduxState,
  selectCurrentEvent,
  selectCurrentUser,
  selectCustomVinInput,
  selectFormsState,
  selectTicket,
} from 'redux/appStore'
import { ICurrentEvent } from 'types/events'
import { setCurrentEvent } from 'redux/currentEvent/currentEventSlice'
import Handlebars from 'handlebars'
import { MailData, Recipients, getEmailRecipients } from '../../../hooks/emails'
import { useServiceProxy } from '../../../hooks/kong'
import { config } from '../../../config'
import { TransformedVehicle } from 'types/ticket'

declare module 'types/form' {
  export interface Forms {
    [CallTypes.RECOVERY]: FormApi
  }
}

export const RecoveryForm: React.FC = () => {
  const { t } = useTranslation()
  const { ticketNumber, pickupInfo } = useSelector(selectTicket)
  const nearestBranch = useNearestBranch()
  const serviceProxy = useServiceProxy()
  const currentUser = useSelector(selectCurrentUser)
  const { recovery, ldr } = useSelector(selectFormsState) as any
  const { update } = useReduxCallStoreActions()
  const event =
    useSelector((state: ReduxState) => selectCurrentEvent(state)(EventTypes.RECOVERY)) || (undefined as unknown as ICurrentEvent)

  const createServiceEvent = useCreateServiceEvent<Recovery>({ eventType: EventTypes.RECOVERY })
  const dispatch = useDispatch()
  const forms = useSelector(selectFormsState)
  const customVinInput = useSelector(selectCustomVinInput)

  if (event) {
    return (
      <>
        <RecoveryEvent {...(event?.event as any)} />
        {ldr && ldr.claim ? (
          ldr.claim.driver ? (
            <Button.Primary
              style={{ width: '100%' }}
              onClick={() => {
                update({
                  ldr: {
                    ...ldr,
                    claim: {
                      ...ldr.claim,
                      towWhere: event?.event?.attributes.destinationName,
                      towWho: event?.event?.attributes.locationBusiness,
                    },
                  },
                })
                forms.CallForm.change('callType', CallTypes.LDR)
              }}
            >
              {t('Return to LDR Claim Creation')}
            </Button.Primary>
          ) : null
        ) : null}
      </>
    )
  }

  const createRecoveryEvent = async (
    state: FormState<
      Omit<Recovery, 'keysWhere' | 'reason'> & {
        keysWhere: SelectOption<string>
        reason: SelectOption<string>
      }
    >,
    caller: FormattedCaller,
  ) => {
    const recovery = state.values
    const { keysWhere, locationOther, reason, reasonOther, vehicle, customVin } = recovery

    const customVinVehicle = {
      year: new Date().getFullYear(), // Set vehicle year to current year
      make: '',
      model: '',
      licensePlate: '',
      licensePlateState: '',
      vin: customVin,
      color: '',
    }

    const { noReplyRecoveryEmailsCsv, rentalAgreementUrn } = config
    const pickupBranch = pickupInfo.branch as BranchInfoFragment
    try {
      const rentalGPBR = pickupBranch.groupBranchNumber
      const rentalGroup = rentalGPBR.substring(0, 2)
      const recoveryGroup = nearestBranch
        ? nearestBranch.additional_data.group_branch_number.substring(0, 2)
        : rentalGPBR.substring(0.2)

      const { data } = await createServiceEvent(
        {
          ...recovery,
          reason: reason.value === 'other' ? reasonOther! : reason.label!,
          keysWhere: keysWhere.value === 'other' ? locationOther! : keysWhere.label!,
          rentalGPBR,
          rentalGroup,
          recoveryGroup,
        },
        undefined,
        (customVin ? customVinVehicle : vehicle) as unknown as TransformedVehicle,
      )

      if (data) {
        dispatch(setCurrentEvent({ eventType: EventTypes.RECOVERY, newEvent: data }))
      }

      const attributes = data && data.attributes

      if (attributes) {
        const destinationLocation = attributes.isDestinationDifferent
          ? transformLocation(attributes.destinationName, attributes.destinationLocation)
          : 'Same as Vehicle Location'

        // Send Recovery Email
        const htmlFile = require('emails/recoveryEmail.html').default
        const compileHandlebars = Handlebars.compile(htmlFile)

        const recoveryValues = {
          ...attributes,
          peopleSoftId: pickupBranch.peopleSoftId,
          ticketNumber,
          rentalContext: data.rentalContext,
          destinationLocation,
          vehicleLocation: attributes.vehicleLocation.addressDescription?.replace('<br/>', ' - '),
          name: caller.callerName,
          phone: caller.formatted,
          agentName: currentUser?.name,
          agentEID: currentUser?.nameID,
        }

        const emailData = {
          to: Recipients.RECOVERY({ rentalGroup, recoveryGroup }),
          from: noReplyRecoveryEmailsCsv,
          subject: 'Vehicle Recovery Notification',
          html: compileHandlebars(recoveryValues),
          peopleSoftId: recoveryValues.peopleSoftId,
          ticketNumber: recoveryValues.ticketNumber,
        }

        return await serviceProxy<MailData>(
          'post',
          `/serviceproxy/email`,
          { 'ehi-device-location-id': emailData.peopleSoftId },
          {
            type: 'HTML',
            context: rentalAgreementUrn + emailData.ticketNumber,
            sender: {
              address: emailData.from,
              displayName: 'Roadside Assistance',
            },
            subject: emailData.subject,
            message: emailData.html,
            to: [...getEmailRecipients(emailData.to)],
          },
        )
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Form
      name={CallTypes.RECOVERY}
      schema={recoverySchema}
      initialValues={recovery}
      autoSaveSync={(recovery) => update({ recovery })}
    >
      <Heading as="h5" spacing="md">
        {t('recovery.heading')}
      </Heading>
      <VehicleSelect label={`${t('recovery.vehicle')}*`} name="vehicle" />
      <Input.Hidden name="customVin" defaultValue={customVinInput || undefined} />
      {!customVinInput ? <Input.Hidden name="vehicle.value" validate={isRequired(t('required.vehicle'))} /> : null}
      <Divider />
      <Heading as="h5" spacing="md">
        {t('Vehicle Location')}
      </Heading>
      <Input label={t('recovery.locationBusiness')} name="locationBusiness" />
      <Margin spacing="md">
        <Location.Vehicle name="vehicleLocation" />
      </Margin>
      <Divider />
      <Heading as="h5">{t('Destination')}</Heading>
      <Margin spacing="md">
        <Checkbox name="isDestinationDifferent" label={t('recovery.isDestinationDifferent')} />
      </Margin>
      <Condition when="isDestinationDifferent" is={true}>
        <Input label={t('recovery.locationBusiness')} name="destinationName" />
        <Margin spacing="md">
          <Location.VehicleDestination label={`${t('recovery.destination')}*`} name="destinationLocation" />
        </Margin>
      </Condition>
      <Divider />
      <CarKeySelect label={`${t('recovery.keysWhere')}*`} name="keysWhere" />
      <Condition when="keysWhere.value" is="other">
        <Input label={`${t('recovery.locationOther')}*`} name="locationOther" />
      </Condition>
      <RecoveryReasonSelect label={`${t('recovery.reason')}*`} name="reason" />
      <Condition when="reason.value" is="other">
        <Input label={`${t('recovery.reasonOther')}*`} name="reasonOther" />
      </Condition>
      <Input label={`${t('recovery.policeReportNumber')}`} name="policeReportNumber" />
      <Input.Textarea label={`${t('recovery.description')}*`} name="description" />
      <Submit onSubmit={createRecoveryEvent}>{t('recovery.submit')}</Submit>
    </Form>
  )
}

const recoverySchema = Yup.object().shape({
  vehicle: Yup.object()
    .shape({
      label: Yup.string().nullable(),
      value: Yup.string().nullable().required('Vehicle is required'),
    })
    .nullable()
    .required('Vehicle is required'),
  locationBusiness: Yup.string().nullable(),
  isDestinationDifferent: Yup.boolean().nullable(),
  destinationLocation: Yup.object()
    .shape({
      label: Yup.string().nullable(),
      value: Yup.string().nullable(),
    })
    .nullable()
    .required(),
  keysWhere: Yup.object()
    .shape({
      label: Yup.string().nullable(),
      value: Yup.string().nullable().required('Keys location is required'),
    })
    .nullable()
    .required('Keys location is required'),
  locationOther: Yup.string()
    .when('keysWhere', {
      is: (keysWhere) => keysWhere.value === 'other',
      then: () => Yup.string().required("Other key location is required if 'Other' is selected"),
    })
    .nullable(),
  description: Yup.string().nullable().required('Description is required'),
  reason: Yup.object()
    .shape({
      label: Yup.string().nullable(),
      value: Yup.string().nullable().required('Reason is required'),
    })
    .nullable()
    .required('Reason is required'),
  reasonOther: Yup.string()
    .when('reason', {
      is: (reason) => reason.value === 'other',
      then: () => Yup.string().required("Other reason is required if 'Other' is selected"),
    })
    .nullable(),
})

const transformLocation = (name: string, location: LocationPayload): string => {
  const address = location?.addressDescription
  let full: string = ''

  if (name) full += name
  if (name && address) full += '<br />'
  if (address) full += address

  return full || 'N/A'
}

export default RecoveryForm
