import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Label, Heading, Paragraph } from 'components/primitives'
import SmartAssignment from 'components/SmartAssignment'
import Button from 'components/Button'
import { pxToRem } from 'theme/utils'
import { omit } from 'lodash-es'
import { motion, AnimatePresence, useCycle } from 'framer-motion'
import IncidentLocation from 'components/IncidentLocation'
import { DynamoEvents } from '../../../types/events'
import { config } from 'config'
import { Participant } from 'types/global'

export const TireEvent = (event: DynamoEvents) => {
  const { t } = useTranslation()
  const {
    vehicle,
    serviceLocations,
    condition,
    network,
    participant,
    exchange,
    recovery,
    tiresChangeSpare,
    ldr,
    tow,
    additionalAction,
    customDropoffDestination,
    customVin,
  } = event.attributes
  const { year, make, model, licensePlate } = vehicle || {}
  const hasDispatched = exchange || recovery || tiresChangeSpare || ldr
  const { tireRepairAccounts } = config
  const [showAccounts, cycleShowAccounts] = useCycle(false, true)
  const [animating, cycleAnimating] = useCycle(false, true)

  const participantLocation = (participant || serviceLocations?.vehicleDestinationLocation) as Participant

  return (
    <>
      <Heading as="h6" spacing="md">
        {t('tires.heading')}
      </Heading>
      <Label>{t('labels.vehicle')}</Label>
      <Paragraph spacing="sm">{customVin ? `VIN: ${customVin}` : `${year} ${make} ${model} (${licensePlate})`}</Paragraph>
      <IncidentLocation.Vehicle location={serviceLocations.vehicleLocation} spacing="sm" />
      <Label>{t('tires.condition')}</Label>
      <Paragraph spacing="sm">{condition}</Paragraph>
      {additionalAction && (
        <>
          <Label>{t('tires.customerChose')}</Label>
          <Paragraph spacing="sm">{additionalAction}</Paragraph>
        </>
      )}
      {network && participantLocation ? (
        <>
          <Label>{t('tires.dropoffType')}</Label>
          <Paragraph spacing="sm">{network.label}</Paragraph>
          <Label>{t('tires.dropoffDestination')}</Label>
          <SmartAssignment.Participant participant={participantLocation} network={network.value} />
        </>
      ) : (
        customDropoffDestination && (
          <IncidentLocation label={t('tires.dropoffDestination')} location={customDropoffDestination} spacing="md" />
        )
      )}
      {hasDispatched && (
        <>
          <Label>{t('labels.dispatchServices')}</Label>
          <Paragraph spacing="sm">
            {exchange && t('labels.exchange')}
            {recovery && (
              <>
                <br />
                {t('labels.recovery')}
              </>
            )}
            {tow && (
              <>
                <br />
                {t('labels.tow')}
              </>
            )}
            {ldr && (
              <>
                <br />
                {t('labels.ldr')}
              </>
            )}
            {tiresChangeSpare && (
              <>
                <br />
                {t('labels.tiresChangeSpare')}
              </>
            )}
          </Paragraph>
        </>
      )}
      {network && participant && (
        <>
          <Button.Link
            type="button"
            style={{
              padding: 0,
              marginBottom: pxToRem(8),
            }}
            disabled={animating}
            onClick={() => cycleShowAccounts()}
          >
            {showAccounts ? t('labels.hide') : t('tires.accounts.label')}
          </Button.Link>
          <AnimatePresence>
            {showAccounts && (
              <motion.section
                key="accounts"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                style={{ overflow: 'hidden' }}
                onLayoutAnimationStart={cycleAnimating}
                onLayoutAnimationComplete={cycleAnimating}
                transition={{ type: 'tween' }}
              >
                {tireRepairAccounts &&
                  Object.entries(omit(tireRepairAccounts, '__typename')).map(([name, number], i) => (
                    <React.Fragment key={i}>
                      <Label>{name}</Label>
                      <Paragraph style={{ margin: 0, paddingBottom: 8 }}>{number as string}</Paragraph>
                    </React.Fragment>
                  ))}
              </motion.section>
            )}
          </AnimatePresence>
        </>
      )}
    </>
  )
}

export default TireEvent
