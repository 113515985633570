import * as React from 'react'
import { useDispatch } from 'react-redux'
import { resetAppState } from 'redux/appSlice'
import { resetCallHistoryEvents } from 'redux/callHistory/callHistorySlice'
import { resetConnectedSearchState } from 'redux/connectedSearch/connectedSearchSlice'
import { resetCurrentEvent } from 'redux/currentEvent/currentEventSlice'
import { resetExchangeSearchState } from 'redux/exchangeSearch/exchangeSearchSlice'
import { resetFleetVehicleState } from 'redux/fleetVehicle/fleetVehicleSlice'
import { resetFormState, updateFormState } from 'redux/form/formSlice'
import { FormStore } from 'types/form'
import { resetLocations } from 'redux/location/locationSlice'
import { resetMapState } from 'redux/map/mapSlice'
import { resetSmartAssignmentState } from 'redux/smartAssignment/smartAssignmentSlice'
import { resetTabState } from 'redux/tabs/tabsSlice'
import { resetTempMarkerState } from 'redux/tempMarker/tempMarkerSlice'
import { resetTicket } from 'redux/ticket/ticketSlice'
import useTimerState from 'hooks/useTimerState'
import { cloneDeep } from 'lodash'
import { resetCustomVinInputState } from 'redux/customVin/customVinSlice'

export const useReduxCallStoreActions = () => {
  const dispatch = useDispatch()
  const timerstate = useTimerState()
  const localFormStore = localStorage.getItem('localFormStore')
  const [actions] = React.useState({
    update: (payload: Partial<FormStore>) => {
      localStorage.setItem(
        'localFormStore',
        cloneDeep(JSON.stringify({ ...(localFormStore && JSON.parse(localFormStore)), ...payload })),
      )
      dispatch(updateFormState({ ...(localFormStore && JSON.parse(localFormStore)), ...payload }))
    },
    reset: () => {
      dispatch(resetAppState())
      dispatch(resetMapState())
      dispatch(resetExchangeSearchState())
      dispatch(resetFormState())
      dispatch(resetFleetVehicleState())
      dispatch(resetTabState())
      dispatch(resetConnectedSearchState())
      dispatch(resetLocations())
      dispatch(resetTicket({}))
      dispatch(resetTempMarkerState())
      dispatch(resetSmartAssignmentState())
      dispatch(resetCurrentEvent())
      dispatch(resetCallHistoryEvents())
      dispatch(resetConnectedSearchState())
      dispatch(resetCustomVinInputState())
      timerstate.set({})
      localStorage.removeItem('localFormStore') // Remove Caller specific form items
    },
  })
  return actions
}

export default useReduxCallStoreActions
