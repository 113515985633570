import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Branch } from 'types/global'
import { Label, Paragraph } from 'components/primitives'
import Margin, { MarginProps } from 'components/Margin'
import { UpdatedTimestamp } from 'components/Dispatch'
import { Modified } from 'types/callstore'
import { isBranch } from 'utils/typeChecks'

type Props = {
  branch?: Branch & Modified
  agentEID?: string | null
  exchangeNotes?: string | null
  accommodationNotes?: string | null
  vehicleAccommodation?: string
  disabledVehicle?: string
  bypassReason?: string | undefined | null
} & MarginProps

const ExchangeBranchDisplay = ({
  branch,
  agentEID,
  exchangeNotes,
  vehicleAccommodation,
  accommodationNotes,
  disabledVehicle,
  bypassReason,
  ...rest
}: Props) => {
  const { t } = useTranslation()
  if (!branch || !isBranch(branch)) return null
  const { brand, modified } = branch

  return (
    <Margin spacing={'none'} {...rest}>
      <Label>{t('Exchange Branch Location')}</Label>
      {modified && <UpdatedTimestamp timestamp={modified} />}
      <Paragraph spacing="md">
        {brand} - {branch?.additional_data?.group_branch_number}
      </Paragraph>
      <Paragraph spacing="md">{branch?.additional_data?.formatted_phone}</Paragraph>
      <Paragraph>{branch?.address?.street_addresses[0]}</Paragraph>
      <Paragraph spacing="md">{branch?.additional_data?.formatted_city_state_zip}</Paragraph>
      {agentEID && (
        <>
          <Label>{t('labels.exchangeApproval')}</Label>
          <Margin spacing="md">{agentEID}</Margin>
        </>
      )}
      {vehicleAccommodation && (
        <>
          <Label>{t('labels.vehicleAccommodationSelect')}</Label>
          <Margin spacing="md">{vehicleAccommodation}</Margin>
        </>
      )}
      {accommodationNotes && (
        <>
          <Label>{t('labels.accommodationNotes')}</Label>
          <Margin spacing="md">{accommodationNotes}</Margin>
        </>
      )}
      {disabledVehicle && (
        <>
          <Label>{t('labels.disabledVehicleSelect')}</Label>
          <Margin spacing="md">{disabledVehicle}</Margin>
        </>
      )}
      {exchangeNotes && (
        <>
          <Label>{t('Exchange notes')}</Label>
          <Margin spacing="md">{exchangeNotes}</Margin>
        </>
      )}
      {bypassReason && (
        <>
          <Label>{t('labels.bypassReasonSelect')}</Label>
          <Margin spacing="md">{bypassReason}</Margin>
        </>
      )}
    </Margin>
  )
}

export default ExchangeBranchDisplay
