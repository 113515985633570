import * as React from 'react'
import { IconType } from 'react-icons'
import { MarkerProps } from '@react-google-maps/api'
import { renderToString } from 'react-dom/server'
import { MdPerson, MdDirectionsCar, MdPlace, MdStoreMallDirectory, MdBuild } from 'react-icons/md'
import { GiCarWheel } from 'react-icons/gi'
import { Marker as TMarker, MarkerType } from 'types/googleMaps'
import { elm, calypso, black, white } from 'theme/colors'
import {
  BodyShopIconMarker,
  RentalIconMarker,
  ReturnIconMarker,
  CustomIconType,
  ConnectedCarMarkerIcon,
  CartwheelIconMarker,
  RentalIcon,
  ReturnIcon,
  BodyShopIcon,
  ExchangeIcon,
} from 'icons'
import { Marker } from 'components/GoogleMaps/Marker'

export type HeroMarkerProps = Pick<MarkerProps, 'onClick'> &
  Pick<TMarker, 'latitude' | 'longitude' | 'type'> & {
    selected?: boolean
    icon?: IconType | CustomIconType
    children?: React.ReactNode
  }

export const GoogleHeroMarker: React.FC<HeroMarkerProps> = ({ type, latitude, longitude, selected, icon, ...rest }) => {
  const markerIcon = getMarkerIconProps(type, icon)

  return <Marker icon={markerIcon} type={type} latitude={latitude} longitude={longitude} {...rest} />
}

// Icon codepoints available: https://fonts.google.com/icons
// Pin backgroundColors available: https://sites.google.com/site/gmapsdevelopment/
export function getMarkerIconProps(type?: MarkerType, icon?: IconType | CustomIconType): any {
  const svgToString = (SvgIcon: any) => {
    const parser = new DOMParser()
    const svgDoc = parser.parseFromString(renderToString(<SvgIcon />), 'image/svg+xml')
    const iconPath = svgDoc.querySelector('path')?.getAttribute('d') as string
    return iconPath
  }
  switch (type) {
    case MarkerType.CONNECTEDCAR:
      return { svgPath: svgToString(ConnectedCarMarkerIcon), color: calypso }
    case MarkerType.RENTAL:
      return { svgPath: svgToString(RentalIconMarker), color: elm }
    case MarkerType.RETURN:
      return { svgPath: svgToString(ReturnIconMarker), color: elm }
    case MarkerType.BODYSHOP:
      return { svgPath: svgToString(BodyShopIconMarker), color: white }
    case MarkerType.TIRESHOP:
      return { svgPath: svgToString(CartwheelIconMarker), color: elm }
    case MarkerType.CALLER:
      return { codePoint: `\ue7fd`, color: '#ffffff', bgColor: 'blu-blank' }
    case MarkerType.VEHICLE:
      return { codePoint: `\ue531`, color: '#ffffff', bgColor: 'red-blank' }
    case MarkerType.VEHICLEDESTINATION:
      return { codePoint: `\ue869`, color: '#ffffff', bgColor: 'purple-blank' }
    case MarkerType.CALLERDESTINATION:
      return { codePoint: `\ue55f`, color: '#ffffff', bgColor: 'purple-blank' }
    case MarkerType.BRANCH:
    case MarkerType.BRANCHES:
      return { codePoint: `\ue8d1`, color: '#000000', bgColor: 'grn-blank' }
    case MarkerType.HOTEL:
    case MarkerType.HOTELS:
      return { codePoint: `\ue53a`, color: '#ffffff', bgColor: 'pink-blank' }
    case MarkerType.RENTALRETURN:
      return { codePoint: `\ue8d1`, color: '#000000', bgColor: 'grn-blank' }
    case MarkerType.REPAIRSHOP:
      return { codePoint: `\ue869`, color: '#ffffff', bgColor: 'purple-blank' }
    case MarkerType.PLACE:
      return { codePoint: `\ue55f`, color: '#000000', bgColor: 'grn-blank' }
    case MarkerType.EXCHANGE:
      return { codePoint: `\ue8d4`, color: '#000000', bgColor: 'grn-blank' }
    default:
      return { codePoint: ` `, color: 'purple-blank', bgColor: 'purple-blank' }
  }
}

type MarkerIconProps = {
  color?: string
  circleSize?: string
  icon: IconType | CustomIconType
  pin: boolean
}
export function getMarkerIcon(type: MarkerType, icon?: IconType | CustomIconType): MarkerIconProps {
  switch (type) {
    case MarkerType.CONNECTEDCAR:
      return { icon: ConnectedCarMarkerIcon, color: calypso, pin: true }
    case MarkerType.CALLER:
      return { icon: MdPerson, color: black, pin: true }
    case MarkerType.VEHICLE:
      return { icon: MdDirectionsCar, color: black, pin: true }
    case MarkerType.VEHICLEDESTINATION:
    case MarkerType.CALLERDESTINATION:
      return { icon: MdPlace, color: black, pin: true }
    case MarkerType.RENTAL:
      return { icon: RentalIcon, color: elm, pin: true }
    case MarkerType.RETURN:
      return { icon: ReturnIcon, color: elm, pin: true }
    case MarkerType.BRANCH:
    case MarkerType.BRANCHES:
      return { icon: MdStoreMallDirectory, color: elm, pin: true }
    case MarkerType.RENTALRETURN:
      return { icon: MdStoreMallDirectory, color: elm, pin: true }
    case MarkerType.BODYSHOP:
      return { icon: BodyShopIcon, color: elm, pin: true }
    case MarkerType.REPAIRSHOP:
      return { icon: MdBuild, color: elm, pin: true }
    case MarkerType.TIRESHOP:
      return { icon: GiCarWheel, color: elm, pin: true }
    case MarkerType.PLACE:
      return { icon: icon || MdPlace, color: elm, pin: false }
    case MarkerType.EXCHANGE:
      return { icon: ExchangeIcon, color: black, pin: true }
    default:
      return { icon: MdPlace, color: elm, pin: true }
  }
}

export default GoogleHeroMarker
