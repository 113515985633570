import * as React from 'react'
import { FieldRenderProps } from 'react-final-form'
import styled from 'styled-components/macro'
import { Input, Label } from 'components/primitives'
import { calypso, silver, white } from 'theme/colors'
import { pxToRem } from 'theme/utils'
import { StyleProps } from 'types/global'

const Indicator = styled.div<{ checked?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${pxToRem(24)};
  width: ${pxToRem(24)};
  min-width: ${pxToRem(24)};
  border-radius: ${pxToRem(24)};
  margin-right: ${pxToRem(16)};
  border: ${(props) => (props.checked ? 'none' : `2px solid ${silver}`)};
  background-color: ${(props) => (props.checked ? calypso : white)};
`

const Dot = styled.div`
  height: ${pxToRem(8)};
  width: ${pxToRem(8)};
  border-radius: ${pxToRem(8)};
  background-color: ${white};
`

const StyledLabel = styled(Label as any)`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`

type Props = {
  hideIndicator?: boolean
} & FieldRenderProps<HTMLInputElement> &
  StyleProps

const Radio = ({ input, children, hideIndicator, meta, ...styleProps }: Props) => (
  <StyledLabel {...styleProps}>
    {!hideIndicator && <Indicator checked={input.checked}>{input.checked && <Dot />}</Indicator>}
    <span style={{ fontSize: 16 }}>{children}</span>
    <Input type="radio" {...(input as any)} />
  </StyledLabel>
)

export default Radio
