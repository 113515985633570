import * as React from 'react'
import Input, { InputProps } from 'components/Input'
import { StylesConfig } from 'react-select'

export const PassengerSelect: React.FC<InputProps> = (props) => {
  const options = [...Array(5).keys()].map((code) => {
    return { label: `${code + 1}`, value: code + 1 }
  })

  return (
    <Input.Select
      {...props}
      styles={{
        menuPortal: (styles: StylesConfig) => ({ ...styles, zIndex: 100000000 }),
      }}
      placeholder="--"
      isClearable
      options={options}
    />
  )
}

export default PassengerSelect
