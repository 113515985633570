import * as React from 'react'
import { Heading } from 'components/primitives'

export const FormHeading = ({ children }: { children: React.ReactNode }) => (
  <Heading as="h5" spacing="md">
    {children}
  </Heading>
)

export default FormHeading
