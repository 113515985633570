import * as React from 'react'
import * as Yup from 'yup'
import { FormApi } from 'final-form'
import { MdBusiness } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectTicket, selectTicketHistoryEvents } from 'redux/appStore'
import useMount from 'hooks/utils/useMount'
import { Form } from 'components/FormManager'
import { Condition, Error } from 'components/fields'
import Margin from 'components/Margin'
import { CallerRelationship } from 'types/callstore'
import { SelectOption } from 'types/global'
import { CallerRelationshipSelect } from 'components/Select'
import Input from 'components/Input'
import { useReduxCallStoreActions, useCallerFormValues } from 'hooks/events'
import formatBirthdate from 'utils/formatBirthdate'
import { ListItem } from 'components/List'
import { TitleText } from 'pages/Ticket/routes/Default/Details/DetailsStyles'
import { OfficerForm } from './OfficerForm'
import CallerSeparator, { UnselectedCallerSeparator } from './CallerSeparator'
import { Flex } from 'components/primitives'
import { isPossiblePhoneNumber } from 'libphonenumber-js'
import { Account, TransformedDriver } from 'types/ticket'
import { useForm } from 'react-final-form'
import { isNil } from 'lodash'
import { getCountryOption } from 'hooks/events/caller/useCallerFormValues'

declare module 'types/form' {
  export interface Forms {
    caller: FormApi
  }
}

type SelectValue = { label: string; value: string } | undefined

export function isCorporateAccount(account: Account) {
  // Two escape conditions -- Checks to see if there is NO account name or NO account number. If true -> Not a corporate account
  if (!account.name || !account.number) return false

  // Checks against ePlus accounts -- If the number is the same as either one --> Not a corporate account
  // Checks against Alamo insider accounts (7015135) as well
  if (account.number === 'ECLAS01' || account.number === '5007125' || account.number === '7015135') {
    return false
  }

  // Everything else should be a corporate account
  return true
}

export function validatePhoneNumber(phoneNumber: string | null | undefined, isoCode: string | null | undefined) {
  if (isoCode?.length) {
    isoCode = isoCode.toUpperCase()
  }
  if (phoneNumber?.length) {
    phoneNumber = phoneNumber.replace(/[^+\d]+/g, '')
  }
  // @ts-ignore
  return phoneNumber?.length && isoCode && isPossiblePhoneNumber(phoneNumber, isoCode)
}

export const CallerForm: React.FC = () => {
  const { update } = useReduxCallStoreActions()
  const currentTicket = useSelector(selectTicket)
  const { drivers, account } = currentTicket
  const callerFormValues = useCallerFormValues(drivers as TransformedDriver[])
  return (
    <Form
      name="caller"
      schema={callerSchema as any}
      initialValues={callerFormValues}
      autoSaveSync={(caller) => update({ caller })}
      keepDirtyOnReinitialize
      initialValuesEqual={() => true}
    >
      {account && isCorporateAccount(account) ? <AccountCallerForm /> : <DriverCallerForm />}
    </Form>
  )
}

function AccountCallerForm() {
  const localFormStore = localStorage.getItem('localFormStore')
  const [initialSelectValue, setInitialSelectValue] = React.useState<SelectValue>(undefined)
  const [optInIsDirty, setOptInIsDirty] = React.useState<boolean>(false)
  const { drivers, account } = useSelector(selectTicket)
  const { t } = useTranslation()
  const form = useForm()
  const eventHistory = useSelector(selectTicketHistoryEvents)
  const isTowProviderForm = form.getState().values.callerRelationship.value === 'towprovider'
  const selectedCaller = form.getState().values.selectedCaller
  const callerFormValues = useCallerFormValues(drivers as TransformedDriver[])
  const localStorageCallerForm = localFormStore && JSON.parse(localFormStore).caller
  const isTowProviderProvided = isTowProviderForm && callerFormValues.callerName !== ''
  const defaultDriver =
    isTowProviderForm && isTowProviderProvided
      ? localStorageCallerForm
        ? localStorageCallerForm.callerName
        : drivers[0]?.fullName
      : callerFormValues.callerName
  const defaultPhone =
    isTowProviderForm && isTowProviderProvided
      ? drivers[0]?.phone[0]?.number
      : localStorageCallerForm && localStorageCallerForm.number && localStorageCallerForm.number.length > 0
        ? localStorageCallerForm.number
        : callerFormValues.number
  const defaultConfirmedDOB = localStorageCallerForm ? localStorageCallerForm.confirmedDOB : callerFormValues.confirmedDOB
  const defaultOptIn = localStorageCallerForm ? localStorageCallerForm.optIn : callerFormValues.optIn
  const defaultConfirmedCompany =
    localStorageCallerForm && localStorageCallerForm.companyConfirmation
      ? localStorageCallerForm.companyConfirmation
      : callerFormValues.companyConfirmation
  const defaultCountryCode =
    isTowProviderForm && isTowProviderProvided // Default to Main Driver phone if Other -> Tow Provider  is selected
      ? getCountryOption(drivers[0]?.phone[0]?.countryCode?.value)
      : localStorageCallerForm && localStorageCallerForm?.countryCode // Pull from localstorage to grab current value
        ? getCountryOption(localStorageCallerForm?.countryCode.value)
        : callerFormValues?.drivers?.[0]?.phone?.[0]?.countryCode?.value // Pull from initial load on CallerForm DRIVER Values
          ? getCountryOption(callerFormValues.drivers[0].phone[0].countryCode.value)
          : localStorageCallerForm && localStorageCallerForm.drivers[0]?.phone[0]?.countryCode // Pull from stored LocalStorage DRIVER values
            ? getCountryOption(localStorageCallerForm.drivers[0]?.phone[0]?.countryCode.value)
            : callerFormValues.countryCode // Default to Initial / base value ( USA )

  React.useEffect(() => {
    if (!isNil(selectedCaller) && selectedCaller.includes('caller-')) {
      form.change('confirmedDOB', defaultConfirmedDOB)
    }
    form.change('countryCode', defaultCountryCode)
    form.change('number', defaultPhone)
  }, [selectedCaller])

  React.useEffect(() => {
    if (!isNil(selectedCaller) && selectedCaller.includes('other') && isTowProviderForm) {
      form.change('callerName', defaultDriver)
      form.change('companyConfirmation', defaultConfirmedCompany)
    }
    form.change('countryCode', defaultCountryCode)
    form.change('number', defaultPhone)
  }, [selectedCaller, isTowProviderForm])

  React.useEffect(() => {
    if (eventHistory && eventHistory.length > 0) {
      const mostRecentEvent = eventHistory[0]
      if (mostRecentEvent && !optInIsDirty) {
        if (mostRecentEvent.attributes) {
          form.change(
            'optIn',
            defaultOptIn
              ? defaultOptIn
              : mostRecentEvent.attributes.customerOptIn // ULY Phone Optin setup
                ? mostRecentEvent.attributes.customerOptIn
                : false, // Default to false
          )
        }
      }
    }
  }, [defaultOptIn, eventHistory, form])

  useMount(() => {
    function checkForCorporateAccount() {
      isCorporateAccount(account) &&
        setInitialSelectValue({
          label: t(`callerRelationship.coworker`),
          value: CallerRelationship.COWORKER,
        })
    }

    checkForCorporateAccount()
  })

  return (
    <>
      <Margin spacing="md">
        <Margin spacing="sm" style={{ fontWeight: 600, color: '#D0021B' }}>
          <Input.Radio name="selectedCaller" value={'account' as any}>
            {`Account: ${account.name}`}
          </Input.Radio>
        </Margin>
        <Margin spacing="md">
          <Condition when="selectedCaller" is="account">
            <CallerSeparator>
              <Margin spacing="sm" style={{ fontWeight: 600, color: '#D0021B' }}>
                <Input.Checkbox name="companyConfirmation" label={`Confirmed Company*`} />
                <Error name="companyConfirmation" />
              </Margin>
            </CallerSeparator>
            <CallerSeparator>
              <CallerRelationshipSelect
                label={t('Type*')}
                validate={({ value }: any) => (value ? undefined : t('Relationship is required'))}
                name="callerRelationship"
                initialValue={initialSelectValue as any}
              />
              <Condition when="callerRelationship" is={(option: SelectOption) => option.value === CallerRelationship.COWORKER}>
                <Margin spacing="md" style={{ fontWeight: 600, color: '#D0021B' }}>
                  <Flex>
                    {isCorporateAccount(account) && <ListItem.Icon icon={MdBusiness} />}
                    <TitleText>{account?.name}</TitleText>
                  </Flex>
                </Margin>
              </Condition>
              <Condition when="callerRelationship" is={(option: SelectOption) => Boolean(option.value)}>
                <Condition
                  when="callerRelationship"
                  is={(option: SelectOption) => Boolean(option.value) && option.value !== CallerRelationship.TOWPROVIDER}
                >
                  <Input label={t('Contact Name*')} name="callerName" placeholder={t('First and Last Name')} />
                  <Input.Phone
                    label={t('Callback Number*')}
                    countryCodeName="countryCode"
                    numberName="number"
                    defaultPhoneCountryCode={defaultCountryCode}
                    defaultPhoneNumber={defaultPhone || ''}
                  />
                </Condition>
                <Condition
                  when="callerRelationship"
                  is={(option: SelectOption) => Boolean(option.value) && option.value === CallerRelationship.TOWPROVIDER}
                >
                  <Input
                    label={t('Customer Name*')}
                    name="callerName"
                    placeholder={t('First and Last Name')}
                    defaultValue={defaultDriver}
                  />
                  <Input.Phone
                    label={''}
                    smallLabel={t('Customer Callback Number*')}
                    countryCodeName="countryCode"
                    numberName="number"
                    defaultPhoneCountryCode={defaultCountryCode}
                    defaultPhoneNumber={defaultPhone || ''}
                  />
                </Condition>
              </Condition>
              <Condition
                when="callerRelationship"
                is={(option: SelectOption) =>
                  option.value !== CallerRelationship.POLICE && option.value !== CallerRelationship.TOWPROVIDER
                }
              >
                <Input.Checkbox name="optIn" label={t('labels.smsOptIn')} onClick={() => setOptInIsDirty(true)} />
              </Condition>
              <Condition when="callerRelationship" is={(option: SelectOption) => option.value === CallerRelationship.POLICE}>
                <OfficerForm />
              </Condition>
            </CallerSeparator>
          </Condition>
        </Margin>
      </Margin>

      {(drivers as TransformedDriver[]).map(({ fullName, dayOfBirth, monthOfBirth }, i) => (
        <Margin spacing="md" key={fullName}>
          <Margin spacing="sm">
            <Input.Radio name="selectedCaller" value={`caller-${i}` as any}>
              {fullName}
            </Input.Radio>
          </Margin>
          <Condition when="selectedCaller" is={(v) => v !== `caller-${i}`}>
            <UnselectedCallerSeparator>
              <Margin spacing="sm">{formatBirthdate(monthOfBirth, dayOfBirth)}</Margin>
            </UnselectedCallerSeparator>
          </Condition>
          <Condition when="selectedCaller" is={`caller-${i}`}>
            <CallerSeparator>
              <Margin spacing="md">{formatBirthdate(monthOfBirth, dayOfBirth)}</Margin>
              <Margin spacing="md">
                <Input.Checkbox name="confirmedDOB" label={t('Confirmed DOB*')} />
                <Error name="confirmedDOB" />
              </Margin>
              <Input.Phone
                label={t('Callback Number*')}
                countryCodeName="countryCode"
                numberName="number"
                defaultPhoneCountryCode={defaultCountryCode}
                defaultPhoneNumber={defaultPhone || ''}
              />
              <Input.Checkbox name="optIn" label={t('labels.smsOptIn')} onClick={() => setOptInIsDirty(true)} />
              <Error name="countryCode" />
              <Error name="number" />
            </CallerSeparator>
          </Condition>
        </Margin>
      ))}

      <Margin spacing="md">
        <Input.Radio name="selectedCaller" value={'other' as any}>
          {t('Other')}
        </Input.Radio>
      </Margin>
      <Error name="selectedCaller" />
      <Margin spacing="md">
        <Condition when="selectedCaller" is="other">
          <CallerSeparator>
            <CallerRelationshipSelect
              label={t('Type*')}
              validate={({ value }: any) => (value ? undefined : t('Relationship is required'))}
              name="callerRelationship"
            />
            <Condition
              when="callerRelationship"
              is={(option: SelectOption) => Boolean(option.value) && option.value !== CallerRelationship.TOWPROVIDER}
            >
              <Condition
                when="callerRelationship"
                is={(option: SelectOption) => Boolean(option.value) && option.value !== CallerRelationship.TOWPROVIDER}
              >
                <Input label={t('Caller Name*')} name="callerName" placeholder={t('First and Last Name')} />
                <Input.Phone
                  label={t('Callback Number*')}
                  countryCodeName="countryCode"
                  numberName="number"
                  defaultPhoneCountryCode={defaultCountryCode}
                  defaultPhoneNumber={defaultPhone || ''}
                />
              </Condition>
              <Condition
                when="callerRelationship"
                is={(option: SelectOption) => Boolean(option.value) && option.value === CallerRelationship.TOWPROVIDER}
              >
                <Input
                  label={t('Customer Name*')}
                  name="callerName"
                  placeholder={t('First and Last Name')}
                  initialValue={defaultDriver}
                />
                <Input.Phone
                  label={''}
                  smallLabel={t('Customer Callback Number*')}
                  countryCodeName="countryCode"
                  numberName="number"
                  defaultPhoneCountryCode={defaultCountryCode}
                  defaultPhoneNumber={defaultPhone || ''}
                />
              </Condition>
            </Condition>
            <Condition
              when="callerRelationship"
              is={(option: SelectOption) => Boolean(option.value) && option.value === CallerRelationship.TOWPROVIDER}
            >
              <Input
                label={t('Customer Name*')}
                name="callerName"
                placeholder={t('First and Last Name')}
                defaultValue={defaultDriver}
              />
              <Input.Phone
                label={''}
                smallLabel={t('Customer Callback Number*')}
                countryCodeName="countryCode"
                numberName="number"
                defaultPhoneCountryCode={defaultCountryCode}
                defaultPhoneNumber={defaultPhone || ''}
              />
            </Condition>
            <Condition
              when="callerRelationship"
              is={(option: SelectOption) => Boolean(option.value) && option.value !== CallerRelationship.POLICE}
            >
              <Input.Checkbox name="optIn" label={t('labels.smsOptIn')} onClick={() => setOptInIsDirty(true)} />
            </Condition>
            <Condition when="callerRelationship" is={(option: SelectOption) => option.value === CallerRelationship.POLICE}>
              <OfficerForm />
            </Condition>
          </CallerSeparator>
        </Condition>
      </Margin>
    </>
  )
}

function DriverCallerForm() {
  const [optInIsDirty, setOptInIsDirty] = React.useState<boolean>(false)
  const localFormStore = localStorage.getItem('localFormStore')
  const { drivers } = useSelector(selectTicket)
  const { t } = useTranslation()
  const form = useForm()
  const eventHistory = useSelector(selectTicketHistoryEvents)
  const isTowProviderForm = form.getState().values.callerRelationship.value === 'towprovider'
  const selectedCaller = form.getState().values.selectedCaller
  const callerFormValues = useCallerFormValues(drivers as TransformedDriver[])
  const localStorageCallerForm = localFormStore && JSON.parse(localFormStore).caller
  const isTowProviderProvided = isTowProviderForm && callerFormValues.callerName !== ''
  const defaultDriver =
    isTowProviderForm && isTowProviderProvided
      ? localStorageCallerForm
        ? localStorageCallerForm.callerName
        : drivers[0]?.fullName
      : callerFormValues.callerName
  const defaultPhone =
    isTowProviderForm && isTowProviderProvided
      ? drivers[0]?.phone[0]?.number
      : localStorageCallerForm && localStorageCallerForm.number && localStorageCallerForm.number.length > 0
        ? localStorageCallerForm.number
        : callerFormValues.number

  const defaultCountryCode =
    isTowProviderForm && isTowProviderProvided // Default to Main Driver phone if Other -> Tow Provider  is selected
      ? getCountryOption(drivers[0]?.phone[0]?.countryCode?.value)
      : localStorageCallerForm && localStorageCallerForm?.countryCode // Pull from localstorage to grab current value
        ? getCountryOption(localStorageCallerForm?.countryCode.value)
        : callerFormValues?.drivers?.[0]?.phone?.[0]?.countryCode?.value // Pull from initial load on CallerForm DRIVER Values
          ? getCountryOption(callerFormValues.drivers[0].phone[0].countryCode.value)
          : localStorageCallerForm && localStorageCallerForm.drivers[0]?.phone[0]?.countryCode // Pull from stored LocalStorage DRIVER values
            ? getCountryOption(localStorageCallerForm.drivers[0]?.phone[0]?.countryCode.value)
            : callerFormValues.countryCode // Default to Initial / base value ( USA )

  React.useEffect(() => {
    if (!isNil(selectedCaller) && selectedCaller.includes('caller-')) {
      form.change('countryCode', callerFormValues.countryCode)
      form.change('number', callerFormValues.number)
    }
  }, [selectedCaller])

  React.useEffect(() => {
    if (!isNil(selectedCaller) && selectedCaller.includes('other') && isTowProviderForm) {
      form.change('callerName', defaultDriver)
      form.change('countryCode', defaultCountryCode)
      form.change('number', defaultPhone)
    }
  }, [selectedCaller, isTowProviderForm])

  React.useEffect(() => {
    if (eventHistory && eventHistory.length > 0) {
      const mostRecentEvent = eventHistory[0]

      if (mostRecentEvent && !optInIsDirty) {
        if (mostRecentEvent.attributes) {
          form.change(
            'optIn',
            mostRecentEvent.attributes.customerOptIn ? mostRecentEvent.attributes.customerOptIn : false, // Default to false
          )
        }
      }
    }
  }, [eventHistory, form])

  return (
    <>
      {(drivers as TransformedDriver[]).map(({ fullName, dayOfBirth, monthOfBirth }, i) => (
        <Margin spacing="md" key={fullName}>
          <Margin spacing="sm">
            <Input.Radio name="selectedCaller" value={`caller-${i}` as any}>
              {fullName}
            </Input.Radio>
          </Margin>
          <Margin spacing="sm">
            <Condition when="selectedCaller" is={(v) => v !== `caller-${i}`}>
              <UnselectedCallerSeparator>
                <Margin spacing="sm">{formatBirthdate(monthOfBirth, dayOfBirth)}</Margin>
              </UnselectedCallerSeparator>
            </Condition>
          </Margin>
          <Condition when="selectedCaller" is={`caller-${i}`}>
            <CallerSeparator>
              <Margin spacing="md">{formatBirthdate(monthOfBirth, dayOfBirth)}</Margin>
              <Margin spacing="md">
                <Input.Checkbox name="confirmedDOB" label={t('Confirmed DOB*')} />
                <Error name="confirmedDOB" />
              </Margin>
              <Input.Phone
                label={t('Callback Number*')}
                countryCodeName="countryCode"
                numberName="number"
                defaultPhoneCountryCode={defaultCountryCode}
                defaultPhoneNumber={defaultPhone || ''}
              />
              <Input.Checkbox name="optIn" label={t('labels.smsOptIn')} onClick={() => setOptInIsDirty(true)} />
              <Error name="countryCode" />
              <Error name="number" />
            </CallerSeparator>
          </Condition>
        </Margin>
      ))}

      <Margin spacing="md">
        <Input.Radio name="selectedCaller" value={'other' as any}>
          {t('Other')}
        </Input.Radio>
      </Margin>
      <Error name="selectedCaller" />

      <Margin spacing="md">
        <Condition when="selectedCaller" is="other">
          <CallerSeparator>
            <CallerRelationshipSelect
              label={t('Type*')}
              validate={({ value }: any) => (value ? undefined : t('Relationship is required'))}
              name="callerRelationship"
            />
            <Condition
              when="callerRelationship"
              is={(option: SelectOption) => Boolean(option.value) && option.value !== CallerRelationship.TOWPROVIDER}
            >
              <Input
                label={t('Caller Name*')}
                name="callerName"
                placeholder={t('First and Last Name')}
                initialValue={defaultDriver as any}
              />
              <Input.Phone
                label={t('Callback Number*')}
                countryCodeName="countryCode"
                numberName="number"
                defaultPhoneCountryCode={defaultCountryCode}
                defaultPhoneNumber={defaultPhone || ''}
              />
            </Condition>
            <Condition
              when="callerRelationship"
              is={(option: SelectOption) => Boolean(option.value) && option.value === CallerRelationship.TOWPROVIDER}
            >
              <Input
                label={t('Customer Name*')}
                name="callerName"
                placeholder={t('First and Last Name')}
                initialValue={defaultDriver as any}
              />
              <Input.Phone
                label={''}
                smallLabel={t('Customer Callback Number*')}
                countryCodeName="countryCode"
                numberName="number"
                defaultPhoneCountryCode={defaultCountryCode}
                defaultPhoneNumber={defaultPhone || ''}
              />
            </Condition>
            <Condition
              when="callerRelationship"
              is={(option: SelectOption) => Boolean(option.value) && option.value !== CallerRelationship.POLICE}
            >
              <Input.Checkbox name="optIn" label={t('labels.smsOptIn')} onClick={() => setOptInIsDirty(true)} />
            </Condition>
            <Condition when="callerRelationship" is={(option: SelectOption) => option.value === CallerRelationship.POLICE}>
              <OfficerForm />
            </Condition>
          </CallerSeparator>
        </Condition>
      </Margin>
    </>
  )
}

const callerSchema = Yup.object()
  .shape({
    selectedCaller: Yup.string().nullable().required('Caller is required'),
    confirmedDOB: Yup.bool().when('selectedCaller', {
      is: (selectedCaller) => selectedCaller !== 'other' && selectedCaller !== 'account',
      then: () => Yup.bool().oneOf([true], 'Confirmation required'),
    }),
    companyConfirmation: Yup.bool().when('selectedCaller', {
      is: (selectedCaller) => selectedCaller === 'account',
      then: () => Yup.bool().oneOf([true], 'Company Confirmation required'),
    }),
    callerName: Yup.string()
      .when('selectedCaller', {
        is: (selectedCaller) => selectedCaller === 'other' || selectedCaller === 'account',
        then: () => Yup.string().required('Caller name is required'),
      })
      .nullable(),
    countryCode: Yup.object()
      .shape({
        label: Yup.string().required(),
        value: Yup.string().required('Country Code is required'),
      })
      .required('Country required'),
    number: Yup.string()
      .nullable()
      .test('valid', 'Invalid phone number', function () {
        return validatePhoneNumber(this.parent.number, this.parent.countryCode.value) as boolean
      })
      .required('Phone number required'),
  })
  .nullable()

export default CallerForm
