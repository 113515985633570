import { FormApi } from 'final-form'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { TicketCharges } from 'types/ticket'
import { AssignmentNetworks } from 'types/global'
import { EventTypes } from 'types/events'
import { CallTypes, Tires } from 'types/callstore'
import Complete from 'components/Complete'
import { VehicleSelect } from 'components/Select'
import Divider from 'components/Divider'
import { Condition } from 'components/fields'
import { Form } from 'components/FormManager'
import Input from 'components/Input'
import Margin from 'components/Margin'
import { Heading, Label } from 'components/primitives'
import Waivers from 'components/Waivers'
import { AvailabilityIcons } from 'components/IconIndicator'
import Location from 'components/Input/Location'
import { NetworkNames } from 'components/Select/NetworkSelect'
import { WorkflowAction } from 'components/Workflow'
import { useCallEvent, useReduxCallStoreActions } from 'hooks/events'
import { RepairOrExchangeActions, DispatchTireChangeActions } from './TireRepairActions'
import TireConditionRadio, { TireCondition } from './TireConditionRadio'
import TireEvent from './TireEvent'
import TireChangeEvent from './TireChangeEvent'
import { isRequired } from 'utils/validators'
import useCreateServiceEvent from '../../../hooks/events/useCreateServiceEvent'
import { useDispatch, useSelector } from 'react-redux'
import { selectCustomVinInput, selectFormsState, selectTicket } from 'redux/appStore'
import { ICurrentEvent } from 'types/events'
import { setCurrentEvent } from 'redux/currentEvent/currentEventSlice'

declare module 'types/form' {
  export interface Forms {
    [CallTypes.TIRES]: FormApi
  }
}

const TireRepair: React.FC = () => {
  const { t } = useTranslation()
  const { update } = useReduxCallStoreActions()
  const { tires } = useSelector(selectFormsState) as any
  const { vehicle } = useSelector(selectTicket)
  const customVinInput = useSelector(selectCustomVinInput)
  const dispatch = useDispatch()

  const customVinVehicle = {
    year: new Date().getFullYear(), // Set vehicle year to current year
    make: '',
    model: '',
    licensePlate: '',
    licensePlateState: '',
    vin: customVinInput,
    color: '',
    equipmentInfo: [],
  }

  const equipmentInfo = vehicle?.equipmentInfo ?? customVinVehicle?.equipmentInfo
  const hasSpareTire =
    Array.isArray(equipmentInfo) &&
    equipmentInfo.some(({ shortDescription }) => shortDescription && shortDescription === 'Spare tire')
  const towEvent = useCallEvent(EventTypes.TOW)
  const ldrEvent = useCallEvent(EventTypes.LDR)
  const tireEvents = useCallEvent(EventTypes.TIRES)
  const exchangeEvent = useCallEvent(EventTypes.EXCHANGE)
  const recoveryEvent = useCallEvent(EventTypes.RECOVERY)
  const tireChangeSpareEvents = useCallEvent(EventTypes.TIRES_CHANGE_SPARE)

  const createServiceEvent = useCreateServiceEvent({ eventType: EventTypes.TIRES } || null) || (null as unknown as ICurrentEvent)

  React.useEffect(() => {
    const submitTires = async () => {
      const event = await createServiceEvent({
        ...tires,
      })
      dispatch(setCurrentEvent({ eventType: EventTypes.TIRES, newEvent: event.data }))
    }
    if (tireEvents === undefined) {
      if (
        (tires?.condition === 'Losing air' && exchangeEvent) ||
        (tires?.condition === 'Completely flat' && towEvent) ||
        (tires?.condition === 'Two Flats' && towEvent && ldrEvent) ||
        (tires?.condition === 'Three or More Tires Flat/Stolen' && recoveryEvent && ldrEvent)
      ) {
        submitTires()
      }
    }
  }, [createServiceEvent, towEvent, tires, tireEvents, exchangeEvent, ldrEvent, recoveryEvent])

  if (tireEvents && tireEvents.event && tireEvents.event.attributes)
    return (
      <>
        {tireChangeSpareEvents && tireChangeSpareEvents.event && (
          <>
            <TireChangeEvent {...tireChangeSpareEvents.event} />
            <Divider style={{ margin: '16px 0' }} />
          </>
        )}
        <TireEvent {...tireEvents.event} />
      </>
    )

  return (
    <Form
      name={CallTypes.TIRES}
      schema={tiresSchema}
      initialValues={
        {
          network: {
            label: NetworkNames[AssignmentNetworks.TIRE],
            value: AssignmentNetworks.TIRE,
          },
          ...tires,
        } as Tires
      }
      autoSaveSync={(tires) => update({ tires })}
    >
      <Heading as="h5" spacing="md">
        {t('tires.heading')}
      </Heading>

      <Margin spacing="md">
        <Label>{t('labels.fees')}</Label>
        <Waivers charge={TicketCharges.FLAT_TIRE_CHARGE} />
      </Margin>
      <Margin spacing="md">
        <Label>{t('features')}</Label>
        <AvailabilityIcons availability={hasSpareTire} label={t('tires.hasSpare')} notAvailableLabel={t('tires.hasNoSpare')} />
      </Margin>
      <Margin spacing="md">
        <VehicleSelect label={t('labels.requiredVehicle')} name="vehicle" />
        <Input.Hidden name="customVin" defaultValue={customVinInput || undefined} />
        {!customVinInput ? <Input.Hidden name="vehicle.value" validate={isRequired(t('required.vehicle'))} /> : null}
      </Margin>
      <Margin spacing="md">
        <Location.Vehicle name="serviceLocations.vehicleLocation" />
        <Input.Hidden name="serviceLocations.vehicleLocation" validate={isRequired('Vehicle location is required')} />
      </Margin>
      <>
        <Divider style={{ margin: '24px 0' }} />

        <TireConditionRadio name="condition" />

        <Condition when="condition" is={TireCondition.DEFLATING}>
          <RepairOrExchangeActions additionalAction={t('tires.continueToDrive')} />
        </Condition>

        <Condition when="condition" is={TireCondition.FLAT}>
          {hasSpareTire ? (
            <DispatchTireChangeActions />
          ) : (
            <>
              <Margin spacing="md">
                <WorkflowAction callType={CallTypes.TOW} eventType={EventTypes.TOW} label={t('tow.dispatch')} required />
              </Margin>
              <Complete eventType={EventTypes.TIRES} />
            </>
          )}
        </Condition>

        <Condition when="condition" is={TireCondition.MULTIPLEFLATS}>
          <Margin spacing="md">
            <WorkflowAction callType={CallTypes.TOW} eventType={EventTypes.TOW} label={t('tow.dispatch')} required />
            <WorkflowAction callType={CallTypes.LDR} eventType={EventTypes.LDR} label={t('ldr.fileLDR')} required />
          </Margin>
          <Complete eventType={EventTypes.TIRES} />
        </Condition>

        <Condition when="condition" is={TireCondition.STOLENTIRES}>
          <Margin spacing="md">
            <WorkflowAction
              callType={CallTypes.RECOVERY}
              eventType={EventTypes.RECOVERY}
              label={t('recovery.dispatch')}
              required
            />
            <WorkflowAction callType={CallTypes.LDR} eventType={EventTypes.LDR} label={t('ldr.fileLDR')} required />
          </Margin>
          <Complete eventType={EventTypes.TIRES} />
        </Condition>
      </>
    </Form>
  )
}

export default TireRepair

const tiresSchema = Yup.object({
  condition: Yup.string().nullable().required('Tire Condition is required'),
})
