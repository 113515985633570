import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Input, { InputProps } from 'components/Input'
import { CancelReasonCode } from 'types/aaa'
import { StylesConfig } from 'react-select'

export const CancelDispatchSelect: React.FC<InputProps> = (props) => {
  const { t } = useTranslation()

  const options = Object.keys(CancelReasonCode).map((code) => {
    return { label: t(`cancelReasonCode.${code}`), value: code }
  })

  return (
    <Input.Select
      {...props}
      styles={{
        menuPortal: (styles: StylesConfig) => ({ ...styles, zIndex: 100000000 }),
      }}
      placeholder="--"
      isClearable
      options={options}
    />
  )
}

export default CancelDispatchSelect
