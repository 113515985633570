import { useMsal } from '@azure/msal-react'
import { jwtDecode } from 'jwt-decode'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setCurrentUser } from 'redux/auth/authSlice'
import { AzurePayload, ProviderProps } from 'types/auth'
import { msalConfig } from './getAzureConfig'

const URGENTLY_BRANCHES = ['767D']
export const createAccessTokenContext = () => {
  const tokenContext = createContext<string | null>(null)

  const Provider = ({ children }: ProviderProps) => {
    const [token, setToken] = useState<string | null>(null)
    const { instance, accounts } = useMsal()
    const dispatch = useDispatch()

    useEffect(() => {
      async function setAzureUserInRedux() {
        const account = accounts[0]

        if (account) {
          const { accessToken } = await instance.acquireTokenSilent({ scopes: msalConfig.scopes, account })
          const attributes = jwtDecode(accessToken) as AzurePayload
          setToken(accessToken)

          const groupBranch = attributes?.groupBranch || attributes?.group_branch || ''
          const employeeId = attributes?.employeeId || attributes?.employee_id || 'N/A'
          const jobTitle = (account?.idTokenClaims?.jobtitle as string) || 'N/A'
          dispatch(
            setCurrentUser({
              user: {
                nameID: employeeId,
                name: account.name || 'N/A',
                groupBranch: groupBranch.split('/')[0],
                roles: attributes?.roles || ['usr.grp-user'], // Default to base user role,
                jobTitle,
                isUrgentlyUser: URGENTLY_BRANCHES.includes(groupBranch),
              },
            }),
          )
        }
      }
      instance && !token && accounts.length > 0 && setAzureUserInRedux()
    }, [token, dispatch, instance, accounts])

    return <tokenContext.Provider value={token}>{children}</tokenContext.Provider>
  }

  const useAccessTokenContext = () => useContext(tokenContext)
  return { Provider, useAccessTokenContext }
}
