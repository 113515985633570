import * as React from 'react'
import { FormApi } from 'final-form'
import { useTranslation } from 'react-i18next'
import MdSpinner from 'react-md-spinner'
import { DynamoEvents, EventTypes } from 'types/events'
import { Escalate, CallTypes } from 'types/callstore'
import { Form } from 'components/FormManager'
import Input from 'components/Input'
import { Heading } from 'components/primitives'
import { VehicleSelect } from 'components/Select'
import VehicleCondition from 'components/VehicleCondition'
import Margin from 'components/Margin'
import { VehicleAccommodationSelect } from 'components/Select'
import { useCallEventsPagination } from 'hooks/events/call/useCallEventsPagination'
import useCreateServiceEvent from 'hooks/events/useCreateServiceEvent'
import { useCallEvent } from 'hooks/events/call/useCallEvents'
import { isRequired } from 'utils/validators'
import { calypso, monza } from 'theme/colors'
import { Condition } from 'components/fields'
import DisabledVehicleSelection from './DisabledVehicleSelection'
import EscalateSubmit, { SubmitParams } from './EscalateSubmit'
import ExchangeBranch from './ExchangeBranch'
import { getEventInfo, getExchangeBranch, transformVehicle, transformExchangeBranch, transformTowLocation } from './utils'
import EscalateEvent from './EscalateEvent'
import ConfirmCallerContact from './ConfirmCallerContact'
import { MailData, Recipients, getEmailRecipients } from '../../../hooks/emails'
import { useServiceProxy } from '../../../hooks/kong'
import Handlebars from 'handlebars'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentEvent } from 'redux/currentEvent/currentEventSlice'
import { selectCurrentUser, selectCustomVinInput } from '../../../redux/appStore'
import { config } from '../../../config'

declare module 'types/form' {
  export interface Forms {
    [CallTypes.ESCALATE]: FormApi
  }
}

function LoadingState() {
  const { t } = useTranslation()

  return (
    <>
      <Heading as="h5" spacing="md" style={{ color: monza, textTransform: 'uppercase' }}>
        {t('Exchange Escalation')}
      </Heading>
      <Margin spacing="sm">
        <MdSpinner singleColor={calypso} size={32} />
      </Margin>
      <div>{t('Loading...')}</div>
    </>
  )
}

function NoEscalationState() {
  const { t } = useTranslation()

  return (
    <Margin spacing="sm">
      <div>{t('No previous event to escalate')}</div>
    </Margin>
  )
}

function SubmitEscalateButton() {
  const { t } = useTranslation()
  const serviceProxy = useServiceProxy()
  const currentUser = useSelector(selectCurrentUser)
  const createServiceEvent = useCreateServiceEvent<Escalate>({
    eventType: EventTypes.ESCALATE,
  })
  const dispatch = useDispatch()

  const createEscalateEvent = async (state: SubmitParams) => {
    const { escalate } = state
    const { noReplyEscalationEmailsCsv, rentalAgreementUrn } = config

    try {
      const event = await createServiceEvent({ ...escalate })
      const {
        vehicleCondition,
        vehicleAccommodation,
        disabledVehicle,
        accommodationNotes,
        ticketNumber,
        customer,
        customerContact,
        customVin,
      } = escalate

      const customVinVehicle = {
        year: new Date().getFullYear(), // Set vehicle year to current year
        make: '',
        model: '',
        licensePlate: '',
        licensePlateState: '',
        vin: customVin,
        color: '',
        equipmentInfo: [],
        label: `VIN: ${customVin}`,
      }

      const vehicle = transformVehicle(escalate.vehicle ?? customVinVehicle)
      const towLocation = transformTowLocation(escalate.towLocation)
      const peopleSoftId = escalate.exchangeBranch.peoplesoftId
      const exchangeBranch = transformExchangeBranch(escalate.exchangeBranch)
      const escalateValues = {
        vehicle,
        towLocation,
        exchangeBranch,
        vehicleCondition,
        vehicleAccommodation,
        disabledVehicle,
        accommodationNotes,
        ticketNumber,
        agentName: currentUser?.name,
        agentEID: currentUser?.nameID,
        customer,
        customerContact: escalate.confirmedContact && escalate.number ? escalate.number : customerContact,
      }

      const htmlFile = require('emails/escalationEmail.html').default
      const compileHandlebars = Handlebars.compile(htmlFile)
      const emailData = {
        to: Recipients.ESCALATE(),
        from: noReplyEscalationEmailsCsv,
        subject: 'ACTION REQUIRED - ROADSIDE ALERT-NO EXCHANGE FOR CUSTOMER',
        html: compileHandlebars(escalateValues),
        peopleSoftId,
        ticketNumber: escalateValues.ticketNumber,
      }

      await serviceProxy<MailData>(
        'post',
        `/serviceproxy/email`,
        { 'ehi-device-location-id': emailData.peopleSoftId },
        {
          type: 'HTML',
          context: rentalAgreementUrn + emailData.ticketNumber,
          sender: {
            address: emailData.from,
            displayName: 'Roadside Assistance',
          },
          subject: emailData.subject,
          message: emailData.html,
          to: [...getEmailRecipients(emailData.to)],
        },
      )
      dispatch(setCurrentEvent({ eventType: EventTypes.ESCALATE, newEvent: event.data }))
    } catch (err) {
      console.error(err)
    }
  }

  return <EscalateSubmit onSubmit={createEscalateEvent}>{t('labels.submitEscalation')}</EscalateSubmit>
}

function EscalateFormValues({ data }: { data: DynamoEvents[] }) {
  const { t } = useTranslation()
  const recentEvent = getEventInfo(data)
  const exchangeBranchInfo = getExchangeBranch({ recentEvent })
  const customVinInput = useSelector(selectCustomVinInput)

  // No Exchange Branch Found - Can't Escalate
  if (!exchangeBranchInfo) return <NoEscalationState />

  const vehicleCondition = recentEvent?.attributes.vehicleConditionSelect

  return (
    <>
      <ConfirmCallerContact />
      <VehicleCondition prevCondition={vehicleCondition} />
      <VehicleSelect label={`${t('labels.requiredVehicle')}`} name="vehicle" />
      <Input.Hidden name="customVin" defaultValue={customVinInput || undefined} />
      {!customVinInput ? <Input.Hidden name="vehicle.value" validate={isRequired(t('required.vehicle'))} /> : null}
      <ExchangeBranch branchInfo={exchangeBranchInfo} />
      <VehicleAccommodationSelect name="vehicleAccommodationSelect" label={`${t('labels.vehicleAccommodationSelect')}*`} />
      <Input.Hidden name="vehicleAccommodationSelect.value" validate={isRequired(t('required.vehicleAccommodation'))} />
      <Condition when="vehicleAccommodationSelect" is={(v) => v?.value !== undefined}>
        {(v: { label?: string }) => (
          <>
            <Input.Hidden name="vehicleAccommodation" defaultValue={v?.label ? (v.label as any) : undefined} />
          </>
        )}
      </Condition>
      <Input.Textarea
        label={`${t('labels.accommodationNotes')}*`}
        name="accommodationNotes"
        validate={isRequired(t('Accommodation notes required'))}
        maxLength="50"
      />
      <DisabledVehicleSelection recentEvent={recentEvent} />
      <SubmitEscalateButton />
    </>
  )
}

const EscalateForm: React.FC<{ initialValues?: any }> = ({ initialValues }) => {
  const { t } = useTranslation()
  const { data, loading } = useCallEventsPagination()
  const escalateEvent = useCallEvent(EventTypes.ESCALATE)
  // Previous Event
  if (escalateEvent && escalateEvent.event) return <EscalateEvent {...escalateEvent.event} />

  // LOADING STATE
  if (loading) return <LoadingState />

  return (
    <Form initialValues={initialValues} name={CallTypes.ESCALATE}>
      <Heading as="h5" spacing="md" style={{ color: monza, textTransform: 'uppercase' }}>
        {t('Exchange Escalation')}
      </Heading>
      {!data || data.events.length === 0 ? <NoEscalationState /> : <EscalateFormValues data={data.events} />}
    </Form>
  )
}

export default EscalateForm
