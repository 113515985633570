import { GeocodeAddress, SuggestedPlace } from 'types/googleMaps'
import { convertToGeocodeAddress } from 'components/useReverseGeocoder'
import {
  useCallerLocation,
  useVehicleLocation,
  useVehicleDestinationLocation,
  useCallerDestinationLocation,
} from './useLocations'
import { useDispatch } from 'react-redux'
import { updateLocation } from 'redux/location/locationSlice'
import { LocationAddress, LocationState } from 'types/location'
import { Marker, MarkerType } from 'types/googleMaps'
import { trackPendoVehiclePlacement } from 'utils/pendo'

export function useCreateUpdateLocation() {
  const reduxNames: { [key: string]: string } = {
    [MarkerType.CALLER]: 'callerLocation',
    [MarkerType.VEHICLE]: 'vehicleLocation',
    [MarkerType.VEHICLEDESTINATION]: 'vehicleDestinationLocation',
    [MarkerType.CALLERDESTINATION]: 'callerDestinationLocation',
  }
  const callerLocation = useCallerLocation()
  const vehicleLocation = useVehicleLocation()
  const vehicleDestinationLocation = useVehicleDestinationLocation()
  const callerDestinationLocation = useCallerDestinationLocation()
  const dispatch = useDispatch()

  const createUpdateLocation = async (
    { type = MarkerType.VEHICLE, address: addr, latitude, longitude, href, place_id, extendedAddress, place }: Marker,
    isConnectedCarPin: boolean = false,
  ) => {
    let p: SuggestedPlace | null = null

    if (place) {
      p = place
    } else if (href) {
      try {
        if (place_id) {
          const geocoder = new google.maps.Geocoder()
          const { results } = await geocoder.geocode({ placeId: place_id }).then((results) => results)

          if (results.length > 0 && results[0].address_components) {
            p = { address: convertToGeocodeAddress(results[0] as any) } as SuggestedPlace
          }
        }
      } catch (e) {
        console.error(e)
      }
    } else {
      p = { address: extendedAddress as GeocodeAddress } as SuggestedPlace
    }
    const splitAddress = formatExtendedAddress(p?.address)

    if (type === MarkerType.CALLER && callerLocation) {
      dispatch(
        updateLocation({
          name: 'callerLocation',
          location: {
            latitude: latitude,
            longitude: longitude,
            address: splitAddress as LocationAddress,
            addressDescription: addr,
            serviceLocationType: MarkerType.CALLER,
          },
        }),
      )
    } else if (type === MarkerType.VEHICLE && vehicleLocation) {
      dispatch(
        updateLocation({
          name: 'vehicleLocation',
          location: {
            latitude: latitude,
            longitude: longitude,
            address: splitAddress as LocationAddress,
            addressDescription: addr,
            serviceLocationType: MarkerType.VEHICLE,
          },
        }),
      )
      trackPendoVehiclePlacement(isConnectedCarPin ? 'connectedCar' : 'address')
    } else if (type === MarkerType.VEHICLEDESTINATION && vehicleDestinationLocation) {
      dispatch(
        updateLocation({
          name: 'vehicleDestinationLocation',
          location: {
            latitude: latitude,
            longitude: longitude,
            address: splitAddress as LocationAddress,
            addressDescription: addr,
            serviceLocationType: MarkerType.VEHICLEDESTINATION,
          },
        }),
      )
    } else if (type === MarkerType.CALLERDESTINATION && callerDestinationLocation) {
      dispatch(
        updateLocation({
          name: 'callerDestinationLocation',
          location: {
            latitude: latitude,
            longitude: longitude,
            address: splitAddress as LocationAddress,
            addressDescription: addr,
            serviceLocationType: MarkerType.CALLERDESTINATION,
          },
        }),
      )
    } else {
      dispatch(
        updateLocation({
          name: reduxNames[type] as keyof LocationState,
          location: {
            latitude: latitude,
            longitude: longitude,
            address: splitAddress as LocationAddress,
            addressDescription: addr,
            serviceLocationType: type,
          },
        }),
      )
    }
  }

  return createUpdateLocation
}

export const formatExtendedAddress = (details: SuggestedPlace['address']) => {
  const place = details as SuggestedPlace['address']
  return {
    streetNumber: place?.HouseNumber,
    street: place?.Street,
    city: place?.City,
    postalCode: place?.PostalCode,
    state: place?.State,
    country: place?.Country,
    countryShortCode: place?.CountryShortCode,
  }
}

export default useCreateUpdateLocation
