import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { Input } from 'components/primitives'

type Props = React.ComponentProps<typeof Input> & FieldRenderProps<HTMLInputElement>

const Textfield: React.FC<Props> = ({ input, meta, ...rest }) => {
  return <Input {...input} {...rest} as="input" type="text" />
}

export default Textfield
