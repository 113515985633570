import * as React from 'react'
import { MarkerType } from 'types/googleMaps'
import ErrorBoundary from 'components/ErrorBoundary'
import { useSelector } from 'react-redux'
import { selectTicket } from 'redux/appStore'
import GoogleBranchMarker from './GoogleBranchMarker'
import { BranchInfoFragment } from 'types/branch'

export const RentalReturnMarkers: React.FC = () => {
  const { pickupInfo, returnInfo } = useSelector(selectTicket)

  if (!pickupInfo || !returnInfo) return null

  const { dateTime: pickupTime, branch: pickupBranch } = pickupInfo
  const { dateTime: returnTime, branch: returnBranch } = returnInfo

  const pickupLocation = pickupBranch as BranchInfoFragment
  const returnLocation = returnBranch as BranchInfoFragment

  return (
    <ErrorBoundary>
      {pickupLocation && returnBranch && pickupLocation.groupBranchNumber === returnLocation.groupBranchNumber ? (
        <GoogleBranchMarker
          markerType={MarkerType.RENTALRETURN}
          {...pickupLocation}
          pickupTime={pickupTime}
          returnTime={returnTime}
        />
      ) : (
        <>
          {pickupLocation && <GoogleBranchMarker markerType={MarkerType.RENTAL} {...pickupLocation} pickupTime={pickupTime} />}
          {returnLocation && <GoogleBranchMarker markerType={MarkerType.RETURN} {...returnLocation} returnTime={returnTime} />}
        </>
      )}
    </ErrorBoundary>
  )
}

export default RentalReturnMarkers
