import * as React from 'react'
import { useForm } from 'react-final-form'
import Button from 'components/Button'
import { Error } from 'components/fields'
import Margin from 'components/Margin'
import { Escalate } from 'types/callstore'
import { formatErrors, scrollErrorIntoView } from 'components/FormManager/Submit'
import { useSelector } from 'react-redux'
import { selectFormsState } from 'redux/appStore'

export type SubmitParams = {
  escalate: Escalate
}

type SubmitProps = {
  onSubmit: (params: SubmitParams) => Promise<void>
  children?: React.ReactNode
}

function ErrorMessage({ error }: { error: string }) {
  return (
    <Margin spacing="md">
      <Error.Message>{error}</Error.Message>
    </Margin>
  )
}

const EscalateSubmit = ({ onSubmit, children }: SubmitProps) => {
  const form = useForm()
  const forms = useSelector(selectFormsState)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string | null>(null)

  return (
    <>
      {error && <ErrorMessage error={error} />}
      <Button.Primary
        style={{ width: '100%' }}
        loading={loading}
        disabled={loading}
        onClick={async () => {
          if (!loading) {
            setError(null)
            setLoading(true)
            try {
              // Validate caller and form state
              const callerForm = forms.caller

              await callerForm.submit() // trigger any errors in caller form
              await form.submit() // trigger any errors in current form

              const callerState = callerForm.getState()
              const state = form.getState()

              if (!callerState.valid || !state.valid) {
                let errorArray = [...Object.keys(callerState.errors as any), ...Object.keys(state.errors as any)]

                if (errorArray.includes('drivers')) {
                  if (callerState.values.selectedCaller === 'account' || callerState.values.selectedCaller === 'other') {
                    errorArray = errorArray.filter((element) => element !== 'drivers')
                  }
                }

                if (errorArray.length) {
                  console.log('Setting Error: ', errorArray)
                  setError(formatErrors(errorArray))
                  scrollErrorIntoView(errorArray[0], false)
                  setLoading(false)
                  return
                }
              }

              const escalate = state.values as Escalate
              await onSubmit({ escalate })
              setLoading(false)
            } catch (err) {
              setLoading(false)
              setError(err as string)
            }
          }
        }}
      >
        {children}
      </Button.Primary>
    </>
  )
}

export default EscalateSubmit
