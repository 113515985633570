import { Marker, MarkerType } from 'types/googleMaps'
import { formatExtendedAddress } from 'hooks/redux/useCreateUpdateLocation'
import { useDispatch } from 'react-redux'
import { updateLocation } from 'redux/location/locationSlice'
import { LocationAddress } from 'types/location'
import { SuggestedPlace } from 'types/googleMaps'

type Params = Pick<Marker, 'latitude' | 'longitude' | 'address' | 'extendedAddress'>

type CreateUpdateLocationFn = (params: Params) => void

export function useReduxExchange(): CreateUpdateLocationFn {
  const dispatch = useDispatch()

  const createUpdateLocation = ({ address: addr, latitude, longitude }: Params) => {
    const splitAddress = formatExtendedAddress(addr as SuggestedPlace['address'])
    dispatch(
      updateLocation({
        name: 'exchangeLocation',
        location: {
          latitude: latitude,
          longitude: longitude,
          address: splitAddress as LocationAddress,
          addressDescription: addr,
          serviceLocationType: MarkerType.EXCHANGE,
        },
      }),
    )
  }

  return createUpdateLocation
}

export default useReduxExchange
