import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Heading, Label, Paragraph } from 'components/primitives'
import DispatchService from 'components/Dispatch/DispatchService'
import TowServiceLocations from './TowServiceLocations'
import EventVehicle from 'components/EventVehicle'
import DispatchUlyService from 'components/Dispatch/DispatchUlyService'
import { DynamoEvents, EventTypes } from 'types/events'
import { useCallEvent } from 'hooks/events'

export default function TowDisabledVehicleEvent(event: DynamoEvents) {
  const { t } = useTranslation()
  const exchangeEvent = useCallEvent(EventTypes.EXCHANGE)
  const { dispatch, unattended, keysLocation, keysLocationOther, notes, serviceCase, vehicle, vehicleCondition, customVin } =
    exchangeEvent?.event?.attributes || event.attributes
  const caseId = serviceCase ? serviceCase?.caseId : null

  const customVinVehicle = {
    year: new Date().getFullYear(), // Set vehicle year to current year
    make: '',
    model: '',
    licensePlate: '',
    licensePlateState: '',
    vin: customVin,
    color: '',
  }

  return (
    <>
      <Heading as="h6" spacing="md">
        {t('Tow Disabled Vehicle')}
      </Heading>
      <EventVehicle vehicleInDispatch={customVin ? customVinVehicle : vehicle} vehicleConditionFromEvent={vehicleCondition} />
      <TowServiceLocations {...event} />

      {unattended && keysLocation && (
        <>
          <Label>{t('Where are the keys located?')}</Label>
          <Paragraph spacing="md">{keysLocationOther || keysLocation.label}</Paragraph>
        </>
      )}
      <Label>{t('notesToProvider.label')}</Label>
      <Paragraph spacing="md">{notes}</Paragraph>
      {dispatch && <DispatchService dispatch={dispatch} />}
      {serviceCase && caseId && <DispatchUlyService caseId={caseId} />}
    </>
  )
}
