import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Paragraph, Label } from 'components/primitives'
import Margin from 'components/Margin'
import { useCallEvent } from 'hooks/events'
import { EventTypes } from 'types/events'
import { PartialVehicle, PartialVehicleWithStringYear } from 'types/urgently'

type EventVehicleProps = {
  vehicleInDispatch?: PartialVehicle | PartialVehicleWithStringYear
  vehicleConditionFromEvent?: string
  isTowDeliveryOnly?: boolean
}

export const EventVehicle = ({ vehicleInDispatch, vehicleConditionFromEvent, isTowDeliveryOnly = false }: EventVehicleProps) => {
  const towEvent = useCallEvent(EventTypes.TOW)
  const { t } = useTranslation()
  const { vehicle: recentEventVehicle, vehicleCondition, customVin } = towEvent?.event?.attributes || {}

  const customVinVehicle = {
    year: new Date().getFullYear(), // Set vehicle year to current year
    make: '',
    model: '',
    licensePlate: '',
    licensePlateState: '',
    vin: customVin,
    color: '',
    equipmentInfo: [],
    label: `VIN: ${customVin}`,
  }

  const vehicle = recentEventVehicle || vehicleInDispatch || customVinVehicle || {}

  if (isTowDeliveryOnly) return null

  const condition = vehicleCondition || vehicleConditionFromEvent
  return (
    <>
      <Margin spacing="md">
        <Label>{t('labels.vehicle')}</Label>
        <Paragraph>{vehicle?.label}</Paragraph>
      </Margin>
      {condition && (
        <Margin spacing="md">
          <Label>{t('labels.vehicleCondition')}</Label>
          <Paragraph>{condition}</Paragraph>
        </Margin>
      )}
    </>
  )
}

export default EventVehicle
