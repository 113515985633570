import * as React from 'react'
import * as Ariakit from '@ariakit/react'
import { useForm } from 'react-final-form'
import { Flex } from 'components/primitives'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import { ErrorMessage } from 'components/fields/Error'
import { useSelector } from 'react-redux'
import { selectFormsState } from 'redux/appStore'

type Props = {
  onSubmit?: () => void | Promise<void> | Promise<any>
}

export const StepNavigation: React.FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string | null>(null)
  const form = useForm()
  const forms = useSelector(selectFormsState)
  const controlsContext = Ariakit.useCompositeContext()
  const currentStepid = controlsContext?.useState('activeId')
  const controlsItems = controlsContext?.useState('items') || []

  const isLast = controlsItems.findIndex(({ id }) => id === currentStepid) + 1 === controlsItems.length
  const isFirst = controlsItems.findIndex(({ id }) => id === currentStepid) === 0

  return (
    <>
      <>
        <Flex style={{ justifyContent: 'space-between' }}>
          <Button.Secondary
            disabled={isFirst}
            onClick={() => {
              controlsContext?.move(controlsContext?.previous())
            }}
          >
            {t('Previous')}
          </Button.Secondary>
          <Button.Primary
            loading={loading}
            onClick={async () => {
              if (!loading) {
                setLoading(true)
                setError(null)
                // Validate caller and form state

                try {
                  const callerForm = forms.caller
                  await callerForm.submit() // trigger any errors in caller form
                  await form.submit() // trigger any errors in current form

                  const { valid } = form.getState()
                  if (valid && !isLast) controlsContext?.move(controlsContext?.next())
                  if (valid && isLast && onSubmit) {
                    await onSubmit()
                    // this is used to avoid having an infinite spinner if the submission fails
                  }
                } catch (e) {
                  console.error(e)
                  setError(t('error.submit') as string)
                }
                setLoading(false)
              }
            }}
          >
            {isLast ? t('Submit') : t('Continue')}
          </Button.Primary>
        </Flex>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </>
    </>
  )
}

export default StepNavigation
