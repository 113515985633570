import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { UlyStatusCodes, ULYCancelReasonCode } from 'types/urgently'
import CancelUlyDispatch from 'components/Dispatch/Cancel/CancelUlyDispatch'
import { Label, Paragraph } from 'components/primitives'
import { UlyCancelDetails } from 'types/urgently'
import { cancelDialogContext } from './DispatchUlyService'
import { isNil } from 'lodash-es'

type StatusProps = {
  caseId?: string | null
  ulyServiceId?: string | null
  ulyStatus?: UlyStatusCodes | null
  cancelDetails?: UlyCancelDetails | null
  disableCancel?: boolean
}

export function DispatchUlyStatus({ caseId, ulyStatus, disableCancel, ulyServiceId, cancelDetails }: StatusProps) {
  const { t } = useTranslation()
  const { cancelled } = cancelDialogContext.useCancelDialogContext()

  const isCancelled =
    ulyStatus === UlyStatusCodes.CANCELLED_BY_CUSTOMER ||
    ulyStatus === UlyStatusCodes.CANCELLED_BY_CUSTOMER_CHARGEABLE ||
    ulyStatus === UlyStatusCodes.CANCELLED_BY_PROVIDER ||
    ulyStatus === UlyStatusCodes.CANCELLED_BY_DRIVER_FOR_CUSTOMER ||
    ulyStatus === UlyStatusCodes.CANCELLED_NOT_IN_SERVICE_AREA ||
    ulyStatus === UlyStatusCodes.CANCELLED_NO_PROVIDERS_FOUND ||
    ulyStatus === UlyStatusCodes.CLOSED_CANCELLED ||
    ulyStatus === UlyStatusCodes.CLOSED_CANCELLED_CHARGEABLE

  const isCompleted = ulyStatus === UlyStatusCodes.COMPLETE_BY_PROVIDER

  const serviceStatus = cancelled
    ? t('cancelDispatch.cancelled')
    : ulyStatus !== null || ulyStatus !== undefined
      ? isCancelled
        ? t('cancelDispatch.cancelled')
        : isCompleted
          ? t('case.completed')
          : (!isNil(ulyStatus) && UlyStatusCodes?.[ulyStatus]?.replace(/[_]+/g, ' ').toLowerCase()) || t('labels.n/a')
      : t('labels.n/a')

  return (
    <>
      <Label>{t('labels.status')}</Label>
      <Paragraph style={{ textTransform: 'capitalize' }} spacing="sm">
        {serviceStatus}
      </Paragraph>
      <Label>{t('labels.dispatchVendor')}</Label>
      <Paragraph spacing="sm">{t('headings.uly')}</Paragraph>
      {/* Don't show ETA/Wait time if status is closed or cancelled */}
      {!isCancelled && !isCompleted && !cancelled && (
        <>
          {!disableCancel && caseId && ulyServiceId && (ulyStatus !== null || ulyStatus !== undefined) && (
            <CancelUlyDispatch caseId={caseId} serviceId={ulyServiceId} />
          )}
        </>
      )}
      {isCancelled && cancelDetails && (
        <>
          <Label>{t('case.cancelReason')}</Label>
          <Paragraph spacing="sm">{ULYCancelReasonCode[cancelDetails.cancelReason as ULYCancelReasonCode]}</Paragraph>
          {cancelDetails.notes && (
            <>
              <Label>{t('case.cancelNotes')}</Label>
              <Paragraph spacing="sm">{cancelDetails.notes}</Paragraph>
            </>
          )}
        </>
      )}
    </>
  )
}

export default DispatchUlyStatus
