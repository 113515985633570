import React from 'react'
import { useTranslation } from 'react-i18next'
import { EventTypes } from 'types/events'
import Input from 'components/Input'
import Submit from 'components/fields/Submit'
import ScheduledAt from 'components/ScheduledAt'
import Margin from 'components/Margin'
import { useCreateSoftService } from 'hooks/aaa'
import { useCreateUlySoftService, useUrgentlyProvider } from 'hooks/urgent.ly'
import { isRequired } from 'utils/validators'
import { CallTypes } from 'types/callstore'
import { useSelector } from 'react-redux'
import { selectFormsState } from 'redux/appStore'
import { BreakdownDetailsForm, BreakdownNote } from '../BreakdownDetails'
import { BreakdownOptions } from '../BreakdownDetails/BreakdownRadio'

function LockoutDispatch() {
  const { t } = useTranslation()
  const { isEnterpriseTicket } = useUrgentlyProvider()
  const createSoftService = useCreateSoftService(EventTypes.LOCKOUT)
  const createUlySoftService = useCreateUlySoftService(EventTypes.LOCKOUT)
  const { lockout } = useSelector(selectFormsState) as any

  let safeToContinue =
    (lockout?.breakdownOption === BreakdownOptions.ROADWAYINTERSTATE && lockout?.unsafe === 'no') ||
    (lockout?.breakdownOption === BreakdownOptions.PARKINGGARAGE && lockout?.garageAccessible === 'yes') ||
    (lockout?.breakdownOption === BreakdownOptions.OTHER && lockout?.accessible === 'yes')

  return (
    <>
      <BreakdownDetailsForm callType={CallTypes.KEYS} />
      {safeToContinue && (
        <>
          <Margin spacing="md">
            <ScheduledAt callType={CallTypes.KEYS} />
            <Input.Hidden name="scheduledAt" />
          </Margin>
          <BreakdownNote
            breakdownOption={lockout?.breakdownOption}
            unsafe={lockout?.unsafe}
            garageAccessible={lockout?.garageAccessible}
            accessible={lockout?.accessible}
          />
          <Input.Textarea
            label={`${t('notesToProvider.label')}*`}
            placeholder={t('notesToProvider.placeholder')}
            name="notes"
            maxLength={240}
            validate={isRequired(t('required.notes'))}
          />
          <Margin spacing="md">
            <Submit
              onSubmit={async (state, caller) => {
                const { secondaryProvider = false, scheduledAt } = state.values

                if (scheduledAt) {
                  return await createUlySoftService(state, caller)
                } else {
                  if (isEnterpriseTicket) {
                    // AAA
                    return secondaryProvider
                      ? await createUlySoftService(state, caller) //Secondary
                      : await createSoftService(state, caller) //Default
                  } else {
                    // Urgently
                    return secondaryProvider
                      ? await createSoftService(state, caller) //Secondary
                      : await createUlySoftService(state, caller) //Default
                  }
                }
              }}
            >
              {t('lockout.dispatch')}
            </Submit>
          </Margin>
        </>
      )}
    </>
  )
}

export default LockoutDispatch
