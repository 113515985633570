import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'redux/appStore'
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios'
import { useLogger } from './logging/useLogger'
import { v4 as uuid } from 'uuid'

interface RetryConfig extends AxiosRequestConfig {
  retry: number
  retryDelay: number
}

export const globalConfig: RetryConfig = {
  retry: 1,
  retryDelay: 1500,
}

const generateRandomHexString = (length: number) => {
  let output = ''
  for (let i = 0; i < length; ++i) {
    output += Math.floor(Math.random() * 16)
      .toString(16)
      .toLowerCase()
  }
  return output
}

export function useAxios() {
  const currentUser = useSelector(selectCurrentUser)
  const instance = axios.create()

  instance.defaults.headers.common['X-B3-SpanId'] = generateRandomHexString(16)
  instance.defaults.headers.common['X-B3-TraceId'] = generateRandomHexString(32)
  instance.defaults.headers.common['Ehi-Workflow-Id'] = uuid()
  instance.defaults.headers.common['Ehi-caller-id'] = `${currentUser?.nameID ?? 'anonymous'}_${navigator.userAgent.split(' ')[0]}`

  const writeLog = useLogger()

  let currentConfigRequest
  instance.interceptors.request.use(async function (config) {
    const headers = { ...config.headers } as AxiosRequestHeaders

    if (headers.Authorization) {
      headers.Authorization = 'Bearer *****'
    }
    currentConfigRequest = {
      data: config.data ?? undefined,
      headers,
      method: config.method,
      url: config.url,
    }

    if (config.url?.includes('googleMaps')) {
      await new Promise((resolve) => setTimeout(resolve, Math.random() * (1000 - 100) + 100))
    }

    // Remove these headers - throwing CORS errors since they're custom headers being passed to open API
    if (!config.url?.includes('/serviceproxy')) {
      delete config.headers['X-B3-SpanId']
      delete config.headers['X-B3-TraceId']
      delete config.headers['Ehi-Workflow-Id']
      delete config.headers['Ehi-caller-id']
    }

    return config
  })

  instance.interceptors.response.use(
    async function (response) {
      return response
    },
    // async function (error) {
    //   const { config } = error

    //   if (!config || !config.retry) {
    //     writeLog('HTTP_REQUEST', currentConfigRequest)
    //     writeLog('HTTP_ERROR', error)
    //     return Promise.reject(error)
    //   }

    //   config.retry -= 1
    //   const delayRetryRequest = new Promise<void>((resolve) => {
    //     setTimeout(() => {
    //       console.log(`Failed Request: ${config.url} --> ${config.retry} more retry attempt(s)`)
    //       resolve()
    //     }, config.retryDelay || 1500)
    //   })

    //   return delayRetryRequest.then(() => instance(config))
    // },
  )

  return instance
}
