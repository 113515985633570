import React from 'react'
import { sortBy, capitalize } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { MdAdd, MdMap, MdRemove, MdTerrain, MdZoomOutMap, MdSatellite } from 'react-icons/md'
import { FaSatellite } from 'react-icons/fa'
import styled from 'styled-components/macro'
import { getMarkerIcon } from './HeroMap/Markers/GoogleMapMarkers/GoogleHeroMarker'
import { mountainMeadow, white, black, silver, calypso, hotPink } from 'theme/colors'
import Divider from 'components/Divider'
import { Flex } from 'components/primitives'
import CircleAction from 'components/CircleAction'
import { pxToRem } from 'theme/utils'
import { CircleActionProps } from 'components/CircleAction/CircleAction'
import { useLocations } from 'hooks/redux/useLocations'
import { useHeroLayers } from './HeroMap/Layers'
import Sidebar from './Sidebar'
import { SidebarPosition } from 'types/global'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState, selectSidebarState, selectMapZoom, selectMapType, selectTicket } from 'redux/appStore'
import { setMapType, setViewport } from 'redux/map/mapSlice'
import { useGoogleMaps } from 'hooks/map'
import { MapTypes, MarkerType } from 'types/googleMaps'
import { setQuickSearchView } from 'redux/quickSearch/quickSearchSlice'
import { ViewTypes } from 'types/global'
import { LocationPayload } from 'types/location'
import { updateSinglePinView } from 'redux/tempMarker/tempMarkerSlice'

enum AREAS {
  MY_PINS = 'myPins',
  QUICK_SEARCH = 'quickSearch',
  MAP_LAYERS = 'mapLayers',
  MAP_CONTROLS = 'mapControls',
  PINS_AND_LAYERS = 'pinsAndLayers',
}

const StyledGrid = styled.div`
  display: grid;
  font-size: ${pxToRem(12)};
  row-gap: ${pxToRem(8)};
  height: 100%;
`

const SidebarItemLabel = ({ children }: { children?: React.ReactNode }) => {
  const isRightSidebarOpen = useSelector((state: ReduxState) => selectSidebarState(state)('right'))

  return (
    <div>
      {isRightSidebarOpen && (
        <div key="label">
          <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', paddingLeft: '5px' }}>{children}</div>
        </div>
      )}
    </div>
  )
}

function SidebarItem({ label, onClick, isOpen, ...props }: CircleActionProps) {
  return (
    <Flex onClick={onClick} style={{ cursor: 'pointer', alignItems: 'center', margin: `${pxToRem(8)} 0` }}>
      <CircleAction iconColor={black} color="transparent" label={label} isOpen={isOpen} {...props} style={{ padding: 0 }} />
      <SidebarItemLabel>{label}</SidebarItemLabel>
    </Flex>
  )
}

function getMapIcon(mapType: string) {
  if (mapType === 'satellite') {
    return FaSatellite
  } else if (mapType === 'hybrid') {
    return MdSatellite
  } else if (mapType === 'terrain') {
    return MdTerrain
  } else {
    return MdMap
  }
}

const SidebarArea: React.FC<{ area: AREAS; showDivider?: boolean; children: React.ReactNode }> = ({
  area,
  children,
  showDivider,
}) => {
  const { t } = useTranslation()
  const isRightSidebarOpen = useSelector((state: ReduxState) => selectSidebarState(state)('right'))

  return (
    <div>
      <div>
        {!isRightSidebarOpen && showDivider && (
          <div key="divider">
            <Divider style={{ width: '100%', borderTop: `1px solid ${silver}`, alignItems: 'center' }} />
          </div>
        )}
      </div>
      <Flex style={{ flexDirection: 'column' }}>
        <div>{isRightSidebarOpen && <div key="label">{t(area)}</div>}</div>
        {children}
      </Flex>
    </div>
  )
}

function RightSidebar() {
  const { t } = useTranslation()
  const { ticketNumber } = useSelector(selectTicket)
  const { layers } = useHeroLayers()
  const locations = useLocations()
  const locs = Object.values(locations).filter(Boolean) as LocationPayload[]
  const mapTypes = ['hybrid', 'roadmap', 'satellite', 'terrain']
  const isRightSidebarOpen = useSelector((state: ReduxState) => selectSidebarState(state)('right'))
  const { map } = useGoogleMaps()
  const zoom = useSelector(selectMapZoom)
  const selectedMapType = useSelector(selectMapType)

  let pins: CircleActionProps[] = []

  //Update map mode with default maptype
  map?.setMapTypeId(selectedMapType)

  // get props for the My Pins section
  if (locs.length > 0) {
    // sort pins to match workflow:
    // 1- Caller
    // 2- Vehicle
    // 3- Destination
    // since our enum is ordered to match the workflow and locTypes are numbers,
    // we can just perform a basic number sorting
    pins = sortBy(locs, 'serviceLocationType').map((location) => {
      const markerType = location.serviceLocationType
      const onClick = () => {
        if (map) {
          map.setCenter(new google.maps.LatLng(location.latitude, location.longitude))
          map.setZoom(zoom < 12 ? 12 : zoom)
        }
      }
      const { icon } = getMarkerIcon(MarkerType[markerType])
      const label = capitalize(markerType.toLowerCase())
      const props = {
        icon,
        onClick,
        label,
      }
      return props
    })
  }

  // if user has dropped pins, add the section to the layout
  const hasPins = Boolean(pins.length)
  const sidebarGridTemplate = {
    gridTemplateRows: '1fr auto',
    gridTemplateColumns: 'auto',
    gridTemplateAreas: `
      "${AREAS.PINS_AND_LAYERS}"
      "${AREAS.MAP_CONTROLS}"
    `,
  }

  const pinsAndLayersTemplate = {
    gridTemplateRows: ''.concat(hasPins ? 'auto ' : '', 'auto 1fr'),
    gridTemplateColumns: 'auto',
    gridTemplateAreas: ''.concat(
      hasPins ? `"${AREAS.MY_PINS}"` : '',
      `
      "${AREAS.QUICK_SEARCH}"
      "${AREAS.MAP_LAYERS}"
    `,
    ),
  }

  const StyledChildren = styled.div`
    ::-webkit-scrollbar {
      width: 0px; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }
    overflow: scroll;
    grid-area: ${AREAS.PINS_AND_LAYERS};
  `

  const MyPinSection = () =>
    React.useMemo(() => {
      return hasPins ? (
        <SidebarArea area={AREAS.MY_PINS}>
          {pins.map((props, i) => (
            <SidebarItem key={i} {...props} isOpen={isRightSidebarOpen} label={pins[i].label} />
          ))}
        </SidebarArea>
      ) : null
      // eslint-disable-next-line
    }, [hasPins, locs.length])

  const RenderSidebar = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    return (
      <Sidebar side={SidebarPosition.RIGHT} padding={pxToRem(16)} collapsedWidth={72} width={200}>
        <StyledGrid style={sidebarGridTemplate}>
          <StyledChildren>
            <StyledGrid style={pinsAndLayersTemplate}>
              {/* My Pins */}
              <MyPinSection />
              {/* Quick Search */}
              <SidebarArea area={AREAS.QUICK_SEARCH} showDivider={hasPins}>
                {layers.map((layer) => {
                  const { id, icon, visible, markerType, label } = layer
                  // clicking on quick search items toggle some functionality
                  // if the item is not currently active => activate it by routing to the category (shows category's pins on the map)
                  // otherwise => deactivate by routing to the base route (removes category's pins from the map)
                  let props: CircleActionProps = {
                    icon,
                    label,
                    onClick: () => {
                      navigate(`/ticket/${ticketNumber}/place/${markerType}`)
                      dispatch(
                        setQuickSearchView({
                          view:
                            markerType === MarkerType.BRANCHES
                              ? ViewTypes.branches
                              : MarkerType.HOTELS
                                ? ViewTypes.hotels
                                : ViewTypes.main,
                        }),
                      )
                    },
                  }
                  if (visible) {
                    props = {
                      ...props,
                      iconColor: white,
                      color: props.label === 'Hotels' ? hotPink : mountainMeadow,
                      onClick: () => {
                        navigate(`/ticket/${ticketNumber}`)
                        dispatch(setQuickSearchView({ view: ViewTypes.main }))
                      },
                    }
                  }
                  return <SidebarItem key={id} {...props} label={label} isOpen={isRightSidebarOpen} />
                })}
              </SidebarArea>
              {/* Map Layers */}
              {/* <SidebarArea area={AREAS.MAP_LAYERS}>
                  <SidebarItem icon={MdTraffic} onClick={() => {console.log("traffic?")}} label={t('Traffic')} />
                </SidebarArea> */}
            </StyledGrid>
          </StyledChildren>
          {/* Map Controls */}
          <SidebarArea area={AREAS.MAP_CONTROLS} showDivider>
            {mapTypes.map((mapType, index) => {
              const label = mapType.charAt(0).toUpperCase() + mapType.slice(1)
              const icon = getMapIcon(mapType)
              let props: CircleActionProps = {
                icon,
                label,
                onClick: () => {
                  map?.setMapTypeId(mapType)
                  dispatch(setMapType({ mapType: MapTypes[mapType] }))
                },
              }
              if (selectedMapType === mapType) {
                props = {
                  ...props,
                  iconColor: white,
                  color: calypso,
                  onClick: () => {
                    map?.setMapTypeId(mapType)
                    dispatch(setMapType({ mapType: MapTypes[mapType] }))
                  },
                }
              }
              return <SidebarItem key={index} {...props} label={label} isOpen={isRightSidebarOpen} />
            })}
            <SidebarItem
              label={t('zoomAll')}
              icon={MdZoomOutMap}
              onClick={() => {
                dispatch(setViewport({ shouldFitBounds: true }))
                dispatch(updateSinglePinView(false))
              }}
              isOpen={isRightSidebarOpen}
            />
            <SidebarItem
              label={t('zoomIn')}
              icon={MdAdd}
              onClick={() => {
                map && map.setZoom((map.getZoom() || zoom) + 1)
              }}
              isOpen={isRightSidebarOpen}
            />
            <SidebarItem
              label={t('zoomOut')}
              icon={MdRemove}
              onClick={() => {
                map && map.setZoom((map.getZoom() || zoom) - 1)
              }}
              isOpen={isRightSidebarOpen}
            />
          </SidebarArea>
        </StyledGrid>
      </Sidebar>
    )
  }

  return RenderSidebar()
}

export default RightSidebar
