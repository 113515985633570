import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CustomVinState } from 'types/customVin'

const initialState: CustomVinState = {
  vinInput: null,
}

const customVinSlice = createSlice({
  name: 'CustomVin',
  initialState,
  reducers: {
    setCustomVinInput: (state: CustomVinState, action: PayloadAction<CustomVinState>) => {
      state.vinInput = action.payload.vinInput
    },
    resetCustomVinInputState: (state: CustomVinState) => {
      state = initialState
      return state
    },
  },
})

export const { setCustomVinInput, resetCustomVinInputState } = customVinSlice.actions

export default customVinSlice.reducer
