import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Heading, Label } from 'components/primitives'
import Divider from 'components/Divider'
import Margin from 'components/Margin'
import Section from 'components/Section'
import DispatchUlyEventProxy from './DispatchUlyEventProxy'
import DispatchAAAEventProxy from './DispatchAAAEventProxy'
import { useSelector } from 'react-redux'
import { selectTicketHistoryEvents } from 'redux/appStore'
import { DynamoEvents } from 'types/events'

const Dispatches: React.FC = () => {
  const { t } = useTranslation()
  const events = useSelector(selectTicketHistoryEvents)

  const aaaEvents = events?.filter((event) => event?.attributes?.dispatch) as DynamoEvents[]
  const ulyEvents = events?.filter((event) => event?.attributes?.provider === 'ULY') as DynamoEvents[]

  return (
    <Section title={t('Recent Dispatches')}>
      <Section.Body>
        <Margin spacing="md">
          <Heading as="h5">{t('headings.aaa')}</Heading>
          <div style={{ marginLeft: 8, marginTop: 12 }}>
            <DispatchEventList data={aaaEvents} limit={5} />
          </div>
        </Margin>
        <Margin spacing="md">
          <Heading as="h5">{t('headings.uly')}</Heading>
          <div style={{ marginLeft: 8, marginTop: 12 }}>
            <DispatchEventList data={ulyEvents} limit={5} />
          </div>
        </Margin>
      </Section.Body>
    </Section>
  )
}

const DispatchEventList = ({
  data,
  limit,
  children,
}: {
  data?: DynamoEvents[] | null
  limit?: number
  children?: React.ReactNode
}) => {
  const { t } = useTranslation()

  // Limit events to first few if hidden or show all
  const events = data && limit ? data?.slice(0, limit) : data

  if (!events || events.length === 0) return <NoDispatches />

  return (
    <>
      {events?.length > 0 ? (
        events.map((event) => (
          <React.Fragment key={event.eventIdentifier}>
            {event?.attributes?.provider === 'ULY' ? (
              <DispatchUlyEventProxy event={event} />
            ) : (
              <DispatchAAAEventProxy event={event} />
            )}
            <Divider />
          </React.Fragment>
        ))
      ) : (
        <Margin spacing="sm">
          {/* shows error text when no dispatch events ??? */}
          <Label>{t('Error loading history')}</Label>
        </Margin>
      )}
      {children}
    </>
  )
}

function NoDispatches() {
  const { t } = useTranslation()
  return (
    <Heading as="h6" style={{ fontStyle: 'italic', opacity: 0.5 }}>
      {t('No Dispatches')}
    </Heading>
  )
}

export default Dispatches
