import { useSelector } from 'react-redux'
import { selectTicket } from 'redux/appStore'
import { useServiceProxy } from 'hooks/kong'
import { BranchInfoFragment } from 'types/branch'
import { QspResponse } from 'types/qsp'

export const useUpdateCreateQSP = () => {
  const proxy = useServiceProxy()
  const { primaryDriver, ticketNumber, pickupInfo } = useSelector(selectTicket)

  const queryQsp = async () => {
    const { data } = await proxy<QspResponse>('get', `/serviceproxy/qsp`, {}, {}, { rentalAgreementNumber: ticketNumber })
    return data[0]?.qspId
  }

  const updateQsp = async (qspId: number, notes: string, qspIsOpen?: boolean) => {
    const pickupBranch = pickupInfo.branch as BranchInfoFragment
    const date = new Date().toISOString()
    const body = JSON.stringify({
      incidentDate: date,
      enteredByUsername: 'HEROQSP',
      reason: {
        category: 8,
        subtype1: 8,
        subtype2: 1,
      },
      incidentResolution: {
        code: 'A2',
        date,
        station: 'N02E23',
        resolvedByUsername: 'HEROQSP',
      },
      methodTakenCode: 'PCS',
      incidentStation: 'N02E23',
      pointOfOccurrenceCode: 4,
      pointOfOccurrenceStationId: pickupBranch.stationId,
      isClosed: !qspIsOpen,
    })

    await proxy<any>('post', `/serviceproxy/qsp/${qspId}/incident`, {}, body)

    const notesWithoutNewlines = notes.replace(/\r?\n|\r/g, ' ')
    const notesBody = JSON.stringify({
      text: notesWithoutNewlines,
      enteredByUser: 'HEROQSP',
      localDateTimeEntered: date,
    })
    await proxy<any>('post', `/serviceproxy/qsp/${qspId}/note`, {}, notesBody)
  }

  const createQsp = async (notes: string) => {
    if (primaryDriver) {
      // TODO: test with a new RA with no qspId
      await proxy(
        'post',
        `/serviceproxy/qsp`,
        {},
        {
          driverProfileId: primaryDriver.individualId,
          rentalAgreementNumber: ticketNumber,
          // note gets created in updateQsp()
          // notes: [
          //   {
          //     enteredByUser: 'HEROQSP',
          //     dateTimeEntered: date,
          //     text: notes,
          //   },
          // ],
        },
      )
    }
  }

  return async (notes: string, qspIsOpen?: boolean) => {
    try {
      let qspId = await queryQsp()
      if (qspId) {
        await updateQsp(qspId, notes, qspIsOpen)
      } else {
        try {
          await createQsp(notes)
          qspId = await queryQsp()
          await updateQsp(qspId, notes, qspIsOpen)
        } catch (e) {
          const qspId = await queryQsp()
          if (qspId) await updateQsp(qspId, notes, qspIsOpen)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export default useUpdateCreateQSP
