import * as React from 'react'
import * as Ariakit from '@ariakit/react'
import { useTranslation } from 'react-i18next'
import { Heading } from 'components/primitives'
import { Condition } from 'components/fields'
import Button from 'components/Button'
import { DirectionBranch, DirectionVehicle } from './Steps'
import { useForm } from 'react-final-form'
import { BackNavigation } from 'components/Step'
import CallTypeIcon from 'forms/CallForm/CallTypeSelection/CallTypeIcon'
import { CallTypes } from 'types/callstore'
import { useDispatch, useSelector } from 'react-redux'
import { selectFormsState } from 'redux/appStore'
import { setSmartAssignmentAssignment } from 'redux/smartAssignment/smartAssignmentSlice'
import { useReduxCallStoreActions } from 'hooks/events'

const TowExchangeSteps: React.FC = () => {
  const stepStore = Ariakit.useCompositeStore()
  const { update } = useReduxCallStoreActions()
  const { t } = useTranslation()
  const form = useForm()
  const forms = useSelector(selectFormsState)
  const dispatch = useDispatch()

  const callTypeIcon = () => <CallTypeIcon callType={CallTypes.ADDITIONAL} gutter="sm" />

  return (
    <>
      <Condition when="towDirection" is={(v) => !v}>
        <BackNavigation
          onClick={() => {
            update({ towExchange: undefined })
            forms.Tow.change('towType', null)
          }}
        />
        <Heading as="h5" spacing="md">
          {t('tow.exchange.methodQuestion')}
        </Heading>

        <Button.Workflow
          iconBefore={callTypeIcon}
          onClick={() => {
            form.change('towDirection', 'disabledVehicle')
            form.change('customerSupplied', true)
            dispatch(setSmartAssignmentAssignment(null))
          }}
        >
          {t('tow.exchange.customerSupplied')}
        </Button.Workflow>

        <Button.Workflow
          iconBefore={callTypeIcon}
          onClick={() => {
            form.change('towDirection', 'disabledVehicle')
            form.change('rideAlong', false)
          }}
        >
          {t('tow.exchange.stm')}
        </Button.Workflow>

        <Button.Workflow
          iconBefore={callTypeIcon}
          onClick={() => {
            form.change('towDirection', 'disabledVehicle')
            form.change('rideAlong', true)
          }}
        >
          {t('labels.rideAlong')}
        </Button.Workflow>

        <Button.Workflow
          iconBefore={callTypeIcon}
          onClick={() => {
            form.change('towDirection', 'branch')
          }}
        >
          {t('tow.exchange.newVehicle')}
        </Button.Workflow>
      </Condition>

      <Condition when="towDirection" is="disabledVehicle">
        <Ariakit.CompositeProvider store={stepStore}>
          <Ariakit.Composite store={stepStore}>
            <DirectionVehicle />
          </Ariakit.Composite>
        </Ariakit.CompositeProvider>
      </Condition>

      <Condition when="towDirection" is="branch">
        <Ariakit.CompositeProvider store={stepStore}>
          <Ariakit.Composite store={stepStore}>
            <DirectionBranch />
          </Ariakit.Composite>
        </Ariakit.CompositeProvider>
      </Condition>
    </>
  )
}

export default TowExchangeSteps
