import * as React from 'react'
import { useField } from 'react-final-form'
import styled from 'styled-components/macro'
import { pxToRem } from 'theme/utils'
import { palette } from 'styled-tools'

export const ErrorMessage = styled.span`
  color: ${palette('monza')};
  font-size: ${pxToRem(12)};
`

const Error = ({ name, id }: { name: string; id?: string }) => {
  const {
    meta: { touched, error },
  } = useField(name, { subscription: { touched: true, error: true } })

  return touched && error ? (
    <ErrorMessage id={id}>{`* ${error.value || error}`}</ErrorMessage>
  ) : (
    <div style={{ height: 12, width: '100%' }} />
  )
}

export default Object.assign(Error, {
  Message: ErrorMessage,
})
