import React from 'react'
import { useTranslation } from 'react-i18next'
import { EventTypes } from 'types/events'
import Input, { YesNoTriggers } from 'components/Input'
import Submit from 'components/fields/Submit'
import ScheduledAt from 'components/ScheduledAt'
import Margin from 'components/Margin'
import { PassengerSelect, LuggageSelect } from 'components/Select'
import useFieldValue from 'hooks/utils/useFieldValue'
import { useCreateTowService } from 'hooks/aaa'
import { useCreateUlyTowService, useUrgentlyProvider } from 'hooks/urgent.ly'
import { isRequired } from 'utils/validators'
import { UnattendedTow } from '../../NoExchange/Steps'
import { CallTypes } from 'types/callstore'
import { useSelector } from 'react-redux'
import { selectFormsState } from 'redux/appStore'
import { BreakdownNote } from 'forms/CallForm/BreakdownDetails'

const DispatchTow = () => {
  const { t } = useTranslation()
  const { tow } = useSelector(selectFormsState) as any
  const { shouldUseUrgently, isThirdParty } = useUrgentlyProvider()
  const createAAATowService = useCreateTowService(EventTypes.EXCHANGE)
  const createULYTowService = useCreateUlyTowService(EventTypes.EXCHANGE)
  const customerWithVehicle = useFieldValue('customerWithVehicle') as YesNoTriggers
  const disabledVehiclePickup = useFieldValue('disabledVehiclePickup') as boolean
  const unattendedTow = customerWithVehicle === YesNoTriggers.NO

  return (
    <>
      {unattendedTow && (
        <>
          <UnattendedTow />
          {disabledVehiclePickup && (
            <>
              <BreakdownNote
                breakdownOption={tow?.breakdownOption}
                unsafe={tow?.unsafe}
                garageAccessible={tow?.garageAccessible}
                accessible={tow?.accessible}
              />
              <Input.Textarea
                label={`${t('notesToProvider.branch')}*`}
                placeholder={t('notesToProvider.placeholder')}
                maxLength={240}
                name="dispatchRentalNotes"
                validate={isRequired('Provider notes are required')}
              />
            </>
          )}
        </>
      )}
      <Margin spacing="md">
        <ScheduledAt callType={CallTypes.TOW} />
        <Input.Hidden name="scheduledAt" />
      </Margin>
      {shouldUseUrgently && (
        <>
          {isThirdParty && (
            <>
              <Margin spacing="md">
                <PassengerSelect
                  name="passengerCount"
                  label={t('labels.passengerCount')}
                  validate={isRequired('Passenger count is required')}
                />
              </Margin>
              <Margin spacing="md">
                <LuggageSelect
                  name="luggageCount"
                  label={t('labels.luggageCount')}
                  validate={isRequired('Luggage count is required')}
                />
              </Margin>
            </>
          )}
        </>
      )}
      <BreakdownNote
        breakdownOption={tow?.breakdownOption}
        unsafe={tow?.unsafe}
        garageAccessible={tow?.garageAccessible}
        accessible={tow?.accessible}
      />
      <Input.Textarea
        label={unattendedTow && disabledVehiclePickup ? `${t('notesToProvider.vehicle')}*` : `${t('notesToProvider.label')}*`}
        placeholder={t('notesToProvider.placeholder')}
        maxLength={240}
        name="notes"
        validate={isRequired('Provider notes are required')}
      />
      <Margin spacing="md">
        <Submit
          onSubmit={async (state, caller) => {
            const { secondaryProvider = false, scheduledAt } = state.values

            if (scheduledAt) {
              return await createULYTowService(state, caller)
            } else {
              if (shouldUseUrgently) {
                // Urgently
                return secondaryProvider
                  ? await createAAATowService(state, caller) //Secondary
                  : await createULYTowService(state, caller) //Default
              } else {
                // AAA
                return secondaryProvider
                  ? await createULYTowService(state, caller) //Secondary
                  : await createAAATowService(state, caller) //Default
              }
            }
          }}
        >
          {t('tow.dispatch')}
        </Submit>
      </Margin>
    </>
  )
}

export default DispatchTow
