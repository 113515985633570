import * as React from 'react'
import { format } from 'date-fns'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
//@ts-ignore
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useForm } from 'react-final-form'
import Input from 'components/Input'
import { Flex, Paragraph } from 'components/primitives'
import Margin from 'components/Margin'
import { CallTypes, SoftService } from 'types/callstore'
import useLocalTimeZone from 'hooks/utils/useLocalTimeZone'
import { isScheduledAtRequired } from 'utils/validators'
import { EventTypes } from 'types/events'
import { useSelector } from 'react-redux'
import { selectFormsState } from 'redux/appStore'
import { LocationState } from 'types/location'
import { isEmpty } from 'lodash'
import { CallTypeToEventType } from 'utils/callstore'
import MdSpinner from 'react-md-spinner'
import { calypso } from '../../theme/colors'
import { pxToRem } from '../../theme/utils'
import { ErrorMessage } from 'components/fields/Error'

type Props = {
  callType: CallTypes
}

type EventsWithServiceLocations = Exclude<EventTypes, EventTypes.EXCHANGE_TURNDOWN | EventTypes.DISPATCH | EventTypes.ADDITIONAL>

export const ScheduledAt: React.FC<Props> = ({ callType }) => {
  const { t } = useTranslation()
  const form = useForm()
  const [startDate, setStartDate] = React.useState<Date | null>(null)
  const [scheduleLater, setScheduleLater] = React.useState(false)
  const eventType = CallTypeToEventType[callType] as EventsWithServiceLocations
  const { [eventType]: previousForm } = useSelector(selectFormsState) as any
  const location = getVehicleServiceLocation()
  const { timezone, timezoneError } = useLocalTimeZone({ lat: location?.latitude, lon: location?.longitude })

  React.useEffect(() => {
    return () => {
      setScheduleLater(false)
      setStartDate(null)
      form.change('scheduleLater', false)
    }
  }, [form])

  function getVehicleServiceLocation() {
    let serviceLocations = {} as LocationState

    if (previousForm && previousForm.serviceLocations) {
      serviceLocations = { ...previousForm.serviceLocations } || {}
    }
    if (form) {
      const formValues = form.getState().values as SoftService
      serviceLocations = { ...formValues.serviceLocations } || {}
    }

    if (isEmpty(serviceLocations)) return { address: undefined, latitude: undefined, longitude: undefined }

    // Handle new Delivery Payload if service has 4 locations.
    if (Object.keys(serviceLocations).length === 4) {
      const vehicleLocation = serviceLocations.vehicleLocation

      return vehicleLocation
    }

    return serviceLocations.vehicleLocation || serviceLocations.callerLocation
  }

  const filterPassedTime = (selectedTime: any, localTimezone: string | null) => {
    let currentDate = new Date()

    if (localTimezone) {
      currentDate = new Date(currentDate.toLocaleString('en-US', { timeZone: localTimezone }))
    }

    const selectedDate = new Date(selectedTime)
    return currentDate.getTime() < selectedDate.getTime()
  }

  function handleDateChange(date: Date | null) {
    if (date === null) return

    let selectedDate = date
    // Get current Date + Time within correct timezone
    const currentDateWithTz = new Date(new Date().toLocaleString('en-US', timezone ? { timeZone: timezone } : undefined))

    // Get current Date + Time for selected date within correct timezone
    const selectedDateWithTz = new Date(selectedDate.toLocaleString('en-US', timezone ? { timeZone: timezone } : undefined))
    // If current time is past a time that was selected - cannot dispatch into past
    if (currentDateWithTz.getTime() > selectedDateWithTz.getTime()) {
      // Set to 2 hours in advance
      selectedDate.setTime(currentDateWithTz.getTime() + 2 * 60 * 60 * 1000)
      // Round to next half hour
      const ms = 1000 * 60 * 30 // convert minutes to ms

      selectedDate.setTime(Math.round(selectedDate.getTime() / ms) * ms)
    }
    setStartDate(selectedDate)
    const isoString = selectedDate.toISOString().slice(0, -1)
    const formattedDateTime = timezone ? (DateTime.fromISO(isoString, { zone: timezone }) as any) : DateTime.local()

    const scheduledAtFormatted = format(formattedDateTime, 'YYYY-MM-DD HH:mm:ss')
    form.change('scheduledAt', scheduledAtFormatted)
  }

  const zone = timezone ? new Date().toLocaleTimeString('en-us', { timeZoneName: 'short', timeZone: timezone }).split(' ')[2] : ''

  return (
    <Flex style={{ flexDirection: 'column' }}>
      {timezone && !timezoneError ? (
        <>
          <Margin spacing="md">
            <Input.RegisteredCheckbox
              name="scheduleLater"
              validate={(val) => val && isScheduledAtRequired(t('Scheduled time is required'), startDate)}
              spacing="sm"
              onClick={() => {
                setScheduleLater((v) => !v)
                if (startDate !== null) {
                  form.change('scheduledAt', '')
                  setStartDate(null)
                }
              }}
            >
              {t('Schedule for a later time?')}
            </Input.RegisteredCheckbox>
          </Margin>
          {scheduleLater && (
            <div style={{ zIndex: 1000 }}>
              <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                filterTime={(selectedTime: any) => filterPassedTime(selectedTime, timezone)}
                showTimeSelect
                dateFormat="MM/dd/yyyy h:mm aa"
                placeholderText="Click to select a date"
                onChangeRaw={(e: any) => e.preventDefault()} // Prevent user input error from typing
                popperPlacement="top-end"
                showPopperArrow={false}
              >
                <Paragraph style={{ fontSize: 12, margin: 8 }}>{timezone ? `Timezone: ${timezone} (${zone})` : null}</Paragraph>
              </DatePicker>
              <Paragraph style={{ fontSize: 12, marginTop: 8 }}>{timezone ? `Timezone: ${timezone} (${zone})` : null}</Paragraph>
            </div>
          )}
        </>
      ) : (
        <>
          {timezoneError && (
            <ErrorMessage style={{ display: 'block' }}>
              {t('Unable to schedule for later, could not find local time zone')}
            </ErrorMessage>
          )}
          {!timezoneError && (
            <Flex>
              <MdSpinner singleColor={calypso} size={24} style={{ margin: `0 ${pxToRem(16)}` }} />
              <Paragraph>{t('Searching for local time zone')}</Paragraph>
            </Flex>
          )}
        </>
      )}
    </Flex>
  )
}

export default ScheduledAt
