import { CaseServiceCall, ULYCustomerContact } from 'types/urgently'
import { EventTypes } from 'types/events'
import { ULY_CLIENT_CODES } from 'types/urgently'
import { SoftService, Tow, TowExchange } from 'types/callstore'
import { FormattedCaller } from 'components/fields/Submit'
import {
  createTowService,
  createSoftService,
  createTowExchangeService,
  addCustomerContact,
  generateDisablementCode,
  splitReverseTwoWayTow,
  SoftServiceCodes,
  generateDisablementNotes,
} from 'utils/uly'
import { useSelector } from 'react-redux'
import { selectCurrentUser, selectCustomVinInput, selectTicket } from 'redux/appStore'
import { isEmpty, isNil } from 'lodash'
import { config } from 'config'
import { CountryCode, getCountryCallingCode, isSupportedCountry } from 'libphonenumber-js'
import { parsePhoneNumber } from 'redux/ticket/ticketHelpers'
import { splitName } from 'utils/splitName'
import { TransformedVehicle } from 'types/ticket'

function getVehicleGVW(vehicle: TransformedVehicle | null) {
  if (isNil(vehicle)) return undefined
  if (isNil(vehicle.equipmentInfo)) return undefined
  if (isEmpty(vehicle.equipmentInfo)) return undefined

  const gvw = vehicle.equipmentInfo.find((equipment) => equipment.shortDescription && equipment.shortDescription.includes('GVWR'))
  if (isNil(gvw)) return undefined

  return gvw.value.toString()
}

export const useULYStandardServices = (eventTypes: ReadonlyArray<EventTypes>) => {
  const {
    ticketNumber,
    brandId,
    pickupInfo: { groupBranchId },
    vehicle,
  } = useSelector(selectTicket)
  const currentUser = useSelector(selectCurrentUser)
  const customVin = useSelector(selectCustomVinInput)
  const agentEID = currentUser?.nameID || 'N/A'

  return (
    service: SoftService | Tow | TowExchange,
    { callerName, countryCode, number, optIn }: FormattedCaller,
  ): CaseServiceCall => {
    const caseId = Math.random().toString(36).slice(8) + '-' + Math.random().toString(36).slice(4)

    const vehicleGVW = getVehicleGVW(vehicle)

    const vehicleSpareTire =
      vehicle?.equipmentInfo?.length !== 0
        ? vehicle?.equipmentInfo?.filter((vehicle) => vehicle.shortDescription && vehicle.shortDescription.includes('Spare tire'))
        : []
    const hasSpareTire = vehicleSpareTire && vehicleSpareTire.length > 0

    const caseTags = [
      { tag: 'group.branch.id', value: groupBranchId },
      { tag: 'ra.number', value: ticketNumber },
      { tag: 'vehicle.unit.number', value: vehicle?.unitNumber || 'N/A' },
      { tag: 'external.case.creator', value: agentEID },
    ]

    if ((service as TowExchange).vehicleAccommodation) {
      const replacementVehicle = (service as TowExchange).vehicleAccommodation
      caseTags.push({
        tag: 'replacement.vehicle',
        value: replacementVehicle,
      })
    }

    let defaultCountry = countryCode.toString().toUpperCase() as CountryCode
    if (!isSupportedCountry(defaultCountry)) defaultCountry = 'US'
    const phoneCountryCode = getCountryCallingCode(defaultCountry)
    const phoneNumber = parsePhoneNumber(number, defaultCountry)
    const formattedPhone = phoneNumber?.number || number

    if (defaultCountry !== 'US') {
      caseTags.push({
        tag: 'intl.phone.number',
        value: formattedPhone,
      })
    }

    const standardService = {
      case: {
        id: caseId,
        clientCode: ULY_CLIENT_CODES[brandId],
        tags: caseTags,
      },
      contactInfo: {},
      customer: {
        id: ticketNumber,
      },
      vehicle: {
        gvw: vehicleGVW,
      },
      services: [],
    } as unknown as CaseServiceCall
    const { firstName, lastName } = splitName(callerName)

    const fullName = `${firstName} ${lastName}`
    let standard = addCustomerContact(standardService, {
      name: fullName,
      phone: formattedPhone.substring(formattedPhone.length - 10),
      countryPhoneCode: `+${phoneCountryCode}`,
      isMobilePhone: 1,
      textOptIn: config.envName !== 'prod' ? 0 : optIn ? 1 : 0,
    } as ULYCustomerContact)

    for (let i = 0; i < eventTypes.length; i++) {
      // Generate ULY Disablement Reason code
      const disablementCode = generateDisablementCode(eventTypes[i], service, hasSpareTire)
      // Generate ULY Disablement Reason Notes
      const disablementNotes = generateDisablementNotes(eventTypes[i], service, hasSpareTire)
      const disablementReason = { code: disablementCode, notes: disablementNotes }

      if (eventTypes[i] === EventTypes.TOW) {
        // Deliver New Vehicle - Caller & Vehicle locations are different - Reverse Tow Exchange ( 4 Locations )
        if (
          !isEmpty(service.serviceLocations.exchangeLocation) &&
          !isEmpty(service.serviceLocations.vehicleDestinationLocation) &&
          Object.keys(service.serviceLocations).length === 4 &&
          (service as TowExchange).towDirection === 'branch'
        ) {
          const [towRequest, exchangeRequest] = splitReverseTwoWayTow(service as Tow | TowExchange)

          // Add in Tow Service for Disabled Vehicle
          standard = createTowService(
            standard,
            { ...(towRequest as Tow), customVin },
            SoftServiceCodes[EventTypes.TOW],
            disablementReason,
          )
          // Add in Exchange Delivery Service for Caller
          standard = createTowService(standard, exchangeRequest as Tow, SoftServiceCodes[EventTypes.TOW], disablementReason)
        } else if (Object.keys(service.serviceLocations).length === 3) {
          // Perform Tow service instead of exchange for this option - exchangeLocation is NOT set for customerSupplied
          if ((service as TowExchange).customerSupplied) {
            standard = createTowService(
              standard,
              { ...(service as Tow), customVin },
              SoftServiceCodes[EventTypes.TOW],
              disablementReason,
            )
          } else {
            // Tow Exchange ( 3 Locations )
            // Deliver New Vehicle - Caller & Vehicle locations are the same
            standard = createTowExchangeService(
              standard,
              { ...(service as TowExchange), customVin },
              SoftServiceCodes[EventTypes.EXCHANGE],
              disablementReason,
            )
          }
        } else {
          console.log('hitting else;', standard)
          // Tow - No Exchange ( 2 Locations )
          // Short Term Mobility - vehicleDestinationLocation & vehicleLocation are set
          // Deliver New Vehicle - Delivery ONLY - callerLocation & exchangeLocation are set
          standard = createTowService(standard, service as Tow, SoftServiceCodes[eventTypes[i]], disablementReason)
        }
      } else {
        if (eventTypes.length === 1) {
          // RSA - Soft Services ( Winch, Fuel, Lockout, etc... )
          standard = createSoftService(
            standard,
            { ...(service as SoftService), customVin },
            SoftServiceCodes[eventTypes[i]],
            disablementReason,
          )
        }
      }
    }

    return standard
  }
}

export default useULYStandardServices
