import * as React from 'react'
import { useForm } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { FormState } from 'final-form'
import Button from 'components/Button'
import { formatPhone, parsePhone } from 'utils/formatPhone'
import { ErrorMessage } from 'components/fields/Error'
import { formatErrors, scrollErrorIntoView } from '../../FormManager/Submit'
import { useSelector } from 'react-redux'
import { selectFormsState } from 'redux/appStore'
import { mergeWith } from 'lodash-es'
import { Callers } from 'types/callstore'
import { SelectOption } from 'types/global'

export type FormattedCaller = {
  callerName: string
  countryCode: SelectOption<any> | string
  number: string
  formatted: string | null
  optIn?: boolean
  children: React.ReactNode
}

export type SubmitProps = {
  disabled?: boolean
  onSubmit: (state: FormState<any>, caller: FormattedCaller) => Promise<any>
  children?: React.ReactNode
}
const Submit = ({ disabled, onSubmit, children }: SubmitProps) => {
  const { t } = useTranslation()
  const form = useForm()
  const forms = useSelector(selectFormsState)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string | null>(null)

  return (
    <div>
      <Button.Primary
        disabled={disabled}
        loading={loading}
        style={{ width: '100%' }}
        onClick={async () => {
          if (!loading) {
            setError(null)
            setLoading(true)

            try {
              // Validate caller and form state
              const callerForm = forms.caller

              await callerForm.submit() // trigger any errors in caller form
              await form.submit() // trigger any errors in current form

              const callerStateValues = callerForm.getState()
              const callerState = mergeWith({}, callerStateValues, callerForm)
              const state = form.getState()

              if (!callerState.valid || !state.valid) {
                let errorArray = [...Object.keys(callerState.errors as any), ...Object.keys(state.errors as any)]
                if (errorArray.includes('drivers')) {
                  if (callerState.values.selectedCaller === 'account' || callerState.values.selectedCaller === 'other') {
                    errorArray = errorArray.filter((element) => element !== 'drivers')
                  }
                }

                if (errorArray.length) {
                  setError(formatErrors(errorArray))
                  scrollErrorIntoView(errorArray[0], false)
                  setLoading(false)
                  return
                }
              }

              const caller = callerState.values

              const error = await onSubmit(state, formatCaller(caller))
              if (error) {
                setError(error)
              }
              setLoading(false)
            } catch (e) {
              console.error(e)
              setError(t('error.submit') as string)
              setLoading(false)
            }
          }
        }}
      >
        {children}
      </Button.Primary>
      {error && <ErrorMessage style={{ display: 'block', marginTop: 8 }}>{error}</ErrorMessage>}
    </div>
  )
}

export const formatCaller = (caller: Callers): FormattedCaller => {
  const { selectedCaller, drivers, countryCode, number, optIn } = caller

  if (selectedCaller === 'other' || selectedCaller === 'account') {
    const { callerName, countryCode, number, optIn } = caller

    return {
      callerName: callerName!,
      formatted: formatPhone(number, countryCode),
      ...parsePhone(number, countryCode),
      optIn,
    } as FormattedCaller
  }

  const driverIndex = selectedCaller ? parseInt(selectedCaller.split('caller-')[1]) : 0
  const selectedDriver = (drivers || [])[driverIndex]

  return {
    callerName: selectedDriver.callerName,
    formatted: formatPhone(number, countryCode),
    ...parsePhone(number, countryCode),
    optIn,
  } as FormattedCaller
}

export default Submit
