import * as React from 'react'
import { IconContext, IconType } from 'react-icons'
import { black, white } from 'theme/colors'
import { CustomIconType } from 'icons'
import Circle from './Circle'
import { StyleProps } from 'types/global'

export type CircleIconProps = {
  color?: string
  circleSize?: string
  icon: IconType | CustomIconType
  iconSize?: number
  ghost?: boolean
  iconColor?: string
  onClick?: () => void
} & StyleProps

const CircleIcon = ({
  icon: Icon,
  iconSize = 24,
  ghost,
  circleSize,
  color = black,
  iconColor,
  style,
  ...rest
}: CircleIconProps) => {
  let styles = {
    backgroundColor: color!,
    color: color === white ? black : white,
    border: 'unset',
    ...style,
  }
  if (ghost) {
    styles = {
      backgroundColor: white,
      color: color!,
      border: `1px solid ${color}`,
      ...style,
    }
  }

  return (
    <Circle circleSize={circleSize!} style={styles} {...rest}>
      <IconContext.Provider value={{ size: String(iconSize), color: iconColor || styles.color }}>
        <Icon fill={iconColor || styles.color} />
      </IconContext.Provider>
    </Circle>
  )
}

export default CircleIcon
