import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Divider from 'components/Divider'
import { Flex, Label, Paragraph } from 'components/primitives'
import { LdrClaim, Insurance } from 'types/callstore'
import FormHeading from './Steps/FormHeading'
import { DriverPhone } from 'types/ticket'
import { TypeCountryOptions } from 'types/global'

type Props = {
  label: string
  children: React.ReactNode
}

export function Field({ label, children }: Props) {
  return (
    <Label spacing="md">
      {label}
      <Paragraph>{children}</Paragraph>
    </Label>
  )
}

export function LDRFields({ claimNumber, claim, callHistoryView = false }: LdrClaim) {
  const { t } = useTranslation()

  if (!claim || !!!claim.driverFirstName)
    return (
      <>
        <FormHeading>{t('ldr.confirmation.heading')}</FormHeading>
        {!claim ? null : <div>{t('Claim Has Been Redacted ')}</div>}
      </>
    )

  const {
    policeReportNumber = '--',
    precinctName = '--',
    policeDepartmentName = '--',
    towWho = '--',
    towWhere = '--',
    driverFirstName = '--',
    driverLastName = '--',
    driverCallbackNumber = '--',
    facts = '--',
    date = '--',
    state: { label: stateLabel = '--' },
    vehicle: { label: vehicleLabel = '--' },
  } = claim

  return (
    <>
      <FormHeading>{t('ldr.confirmation.heading')}</FormHeading>
      {claimNumber && (
        <>
          <Field label={t('ldr.claimNumber')}>{claimNumber}</Field>
          <Divider />
        </>
      )}
      <Field label={t('ldr.confirmation.vehicle')}>{vehicleLabel}</Field>
      <Field label={t('ldr.confirmation.dateOfLoss')}>{date}</Field>
      <Field label={t('ldr.confirmation.factsOfLoss')}>{facts}</Field>
      <Field label={t('ldr.confirmation.state')}>{stateLabel}</Field>
      <Divider />
      <FormHeading>{t('ldr.confirmation.policeReport')}</FormHeading>
      {claim.policeReport === 'no' && <Field label={t('ldr.claim.policeReport')}>{t('No')}</Field>}
      {claim.policeReport === 'yes' && (
        <>
          <Field label={t('ldr.confirmation.policeReportNumber')}>{policeReportNumber}</Field>
          <Field label={t('ldr.confirmation.precinct')}>{precinctName}</Field>
          <Field label={t('ldr.confirmation.department')}>{policeDepartmentName}</Field>
        </>
      )}
      <FormHeading>{t('ldr.confirmation.towDetails')}</FormHeading>
      {claim.roadsideTow === 'no' && <Field label={t('ldr.claim.otherTow')}>{t('No')}</Field>}
      {claim.roadsideTow === 'yes' && (
        <>
          <Field label={t('ldr.confirmation.towWho')}>{towWho}</Field>
          <Field label={t('ldr.confirmation.towWhere')}>{towWhere}</Field>
        </>
      )}
      <FormHeading>{callHistoryView ? t('ldr.confirmation.driverCallHistory') : t('ldr.confirmation.driver')}</FormHeading>
      <Field label={t('ldr.confirmation.driverFirstName')}>{driverFirstName}</Field>
      <Field label={t('ldr.confirmation.driverLastName')}>{driverLastName}</Field>
      <Field label={t('ldr.confirmation.driverCallbackNumber')}>{driverCallbackNumber}</Field>
    </>
  )
}

// const defaultLegalId = {
//   countrySubdivision: '--',
//   countryOption: {
//     label: '--',
//     value: '--',
//   },
//   issueDate: null,
//   expirationDate: null,
//   number: '--',
// }

// const defaultAddress = {
//   typeOption: {
//     label: '--',
//     value: '--',
//   },
//   line1: '--',
//   line2: '--',
//   line3: '--',
//   city: '--',
//   countryOption: {
//     label: '--',
//     value: '--',
//   },
//   countrySubdivision: '--',
//   postalCode: '--',
//   type: 1,
// }

// export function PartyConfirmation({
//   firstName = '--',
//   middleName = '--',
//   lastName = '--',
//   suffix = '--',
//   legalId,
//   address,
//   phone1,
//   phone2,
//   phone3,
//   email = '--',
// }: Party) {
//   const { t } = useTranslation()

//   if (!legalId) {
//     legalId = defaultLegalId
//   } else {
//     legalId = { ...defaultLegalId, ...pickBy(legalId, identity) }
//   }

//   if (!address) {
//     address = defaultAddress
//   } else {
//     address = { ...defaultAddress, ...pickBy(address, identity) }
//   }

//   return (
//     <>
//       <Field label={t('First Name')}>{firstName}</Field>
//       <Field label={t('Middle Name')}>{middleName}</Field>
//       <Field label={t('Last Name')}>{lastName}</Field>
//       <Field label={t('Suffix')}>{suffix}</Field>
//       <Flex style={{ justifyContent: 'space-between' }}>
//         <Field label={t('DL Issuing Country')}>{legalId.countryOption && legalId.countryOption.label}</Field>
//         <div style={{ textAlign: 'right' }}>
//           <Field label={t('DL Issuing State/Province')}>{legalId.countrySubdivision}</Field>
//         </div>
//       </Flex>
//       <Flex style={{ justifyContent: 'space-between' }}>
//         <Field label={t('DL Issue Date')}>
//           {legalId.issueDate
//             ? t('date.standard', {
//                 date: new Date(legalId.issueDate),
//               })
//             : '--'}
//         </Field>
//         <div style={{ textAlign: 'right' }}>
//           <Field label={t('DL Expiration Date')}>
//             {legalId.expirationDate
//               ? t('date.standard', {
//                   date: new Date(legalId.expirationDate),
//                 })
//               : '--'}
//           </Field>
//         </div>
//       </Flex>
//       <Field label={t('Drivers License Number')}>{legalId.number}</Field>
//       <Divider />
//       <FormHeading>{t('Contact Information')}</FormHeading>
//       <Field label={t('Address Type')}>{address.typeOption && address.typeOption.label}</Field>
//       <Field label={t('Street Address 1')}>{address.line1}</Field>
//       <Field label={t('Street Address 2')}>{address.line2}</Field>
//       <Field label={t('Street Address 3')}>{address.line3}</Field>
//       <Flex style={{ justifyContent: 'space-between' }}>
//         <Field label={t('City/Town')}>{address.city}</Field>
//         <div style={{ textAlign: 'right' }}>
//           <Field label={t('Country')}>{address.countryOption && address.countryOption.label}</Field>
//         </div>
//       </Flex>
//       <Flex style={{ justifyContent: 'space-between' }}>
//         <Field label={t('State/Province')}>{address.countrySubdivision}</Field>
//         <div style={{ textAlign: 'right' }}>
//           <Field label={t('Postal Code')}>{address.postalCode}</Field>
//         </div>
//       </Flex>
//       <Divider />
//       <FormHeading>{t('ldr.party.phoneEmail')}</FormHeading>
//       <PhoneDisplay phone={phone1} />
//       <PhoneDisplay phone={phone2} />
//       <PhoneDisplay phone={phone3} />
//       <Field label={t('Email')}>{email}</Field>
//     </>
//   )
// }

export function InsuranceConfirmation({
  companyName = '--',
  claimNumber = '--',
  policyNumber = '--',
  effectiveDate = '--',
  expirationDate = '--',
  coverage = '--',
  contactFirstName = '--',
  contactLastName = '--',
  phone,
  email = '--',
}: Insurance) {
  const { t } = useTranslation()

  return (
    <>
      <Field label={t('Company Name')}>{companyName}</Field>
      <Field label={t('Claim Number')}>{claimNumber}</Field>
      <Field label={t('Policy Number')}>{policyNumber}</Field>
      <Flex style={{ justifyContent: 'space-between' }}>
        <Field label={t('Effective Date')}>{effectiveDate}</Field>
        <div style={{ textAlign: 'right' }}>
          <Field label={t('Expiration Date')}>{expirationDate}</Field>
        </div>
      </Flex>
      <Field label={t('Coverage')}>{coverage}</Field>
      <Flex style={{ justifyContent: 'space-between' }}>
        <Field label={t('Contact First Name')}>{contactFirstName}</Field>
        <div style={{ textAlign: 'right' }}>
          <Field label={t('Contact Last Name')}>{contactLastName}</Field>
        </div>
      </Flex>
      <PhoneDisplay phone={phone} />
      <Field label={t('Email')}>{email}</Field>
    </>
  )
}

export function PhoneDisplay({ phone }: { phone?: (DriverPhone & TypeCountryOptions) | null }) {
  const { t } = useTranslation()
  if (!phone) return null
  const { number, typeOption, countryOption } = phone
  return (
    <>
      <Field label={t('Country Code')}>{countryOption && countryOption.label ? countryOption.label : '--'}</Field>
      <Field label={t('Phone')}>{number || '--'}</Field>
      <Field label={t('Phone Type')}>{typeOption && typeOption.label ? typeOption.label : '--'}</Field>
    </>
  )
}

export default LDRFields
