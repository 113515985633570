import { format } from 'date-fns'
import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

// lazy load on languageChanged
const dateLocales = {
  en: require('date-fns/locale/en'),
}
const getLanguageOnly = (lang: string) => lang.split('-')[0]
// @ts-ignore
i18n
  .use(detector)
  .use(backend)
  // @ts-ignore
  .use(initReactI18next) // passes i18n down to react-i18next
  // @ts-ignore
  .init({
    lng: 'en',
    fallbackLng: 'en', // use en if detected lng is not available
    load: 'languageOnly',
    backend: {
      queryStringParams: { v: process.env.REACT_APP_VERSION },
    },
    interpolation: {
      escapeValue: false, // not needed for react!!

      format: (value, formatStr, lang) => {
        const languageOnly = getLanguageOnly(lang as string)

        // Date formatting
        if (value instanceof Date) {
          // @ts-ignore
          return format(value, formatStr, { locale: dateLocales[languageOnly] })
        }

        return value
      },
    },

    // have a common namespace used around the full app
    ns: ['common'],
    defaultNS: 'common',

    react: {
      wait: true,
    },
  })

export default i18n
