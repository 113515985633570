import * as React from 'react'

import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { MdChevronLeft } from 'react-icons/md'

import Margin from 'components/Margin'
import RouteLink from 'components/RouteLink'

import { StyledBox } from 'components/ScrollBox'

import { useGoogleMapsHotelDetails } from 'hooks/map/useGoogleMapsHotelDetails'
import Places from 'components/Places'
import HotelLocation from './HotelLocation'

export const Hotel = () => {
  const { t } = useTranslation()
  const { hotelId } = useParams()

  const { data, loading, error } = useGoogleMapsHotelDetails(hotelId ?? '')
  if (!data || loading || error) return null

  return (
    <>
      <Margin use={RouteLink} to="../" spacing="md">
        <MdChevronLeft />
        {t('Back')}
      </Margin>
      <Places type="lodging" />

      <StyledBox>
        <HotelLocation {...data} />
      </StyledBox>
    </>
  )
}

export default Hotel
