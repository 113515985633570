import React from 'react'
import * as Yup from 'yup'
import { FormApi } from 'final-form'
import { useDispatch, useSelector } from 'react-redux'
import { selectCustomVinInput, selectTicket } from 'redux/appStore'
import Input from 'components/Input'
import { Form } from 'components/FormManager'
import colors from 'theme/colors'
import { MdClose, MdCheck, MdDirectionsCar } from 'react-icons/md'
import { Flex } from 'components/primitives'
import { ListItem } from 'components/List'
import { useFieldValue } from 'hooks/utils'
import { setCustomVinInput } from 'redux/customVin/customVinSlice'

declare module 'types/form' {
  export interface Forms {
    customVin: FormApi
  }
}

type InputFormProps = {
  confirmClick: () => void
  cancelClick: () => void
}

const VinInputField = ({ confirmClick, cancelClick }: InputFormProps) => {
  const ticket = useSelector(selectTicket)
  const customVinInput = useSelector(selectCustomVinInput)
  const dispatch = useDispatch()
  const inputValue = useFieldValue('vinInput')

  const handleOnClick = () => {
    const vinInput = inputValue !== '' ? inputValue : (customVinInput ?? ticket.vehicle?.vin)
    dispatch(setCustomVinInput({ vinInput }))
    confirmClick()
  }

  return (
    <>
      <Flex style={{ marginTop: 8 }}>
        <ListItem.Icon icon={MdDirectionsCar} />
      </Flex>
      <Input.Textfield
        name="vinInput"
        placeholder="Enter in the vehicle VIN"
        maxLength={17}
        defaultValue={customVinInput ?? undefined}
      />
      <MdClose style={{ cursor: 'pointer', marginLeft: 4, marginTop: 8 }} color={colors.cardinal} onClick={cancelClick} />
      <MdCheck style={{ cursor: 'pointer', marginLeft: 4, marginTop: 8 }} color={colors.mountainMeadow} onClick={handleOnClick} />
    </>
  )
}

export const VehicleVinInputForm = (props: InputFormProps) => {
  return (
    <Form name="customVin" schema={schema}>
      <Flex>
        <VinInputField {...props} />
      </Flex>
    </Form>
  )
}

const schema = Yup.object().shape({
  vinInput: Yup.string().required('Please enter in a vehicle vin'),
})
