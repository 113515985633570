import * as React from 'react'
import { Branch, BranchInfoFragment } from 'types/global'
import { useFieldValue } from 'hooks/utils'
import ExchangeSearch from './ExchangeSearch'
import ExchangeBranch from './ExchangeBranch'
import { LocationPayload } from 'types/location'
import { useCallerLocation } from 'hooks/redux/useLocations'

type Props = {
  name: string
  searchLocation?: LocationPayload | undefined | null
  onSetBranch?: (branch: Branch | BranchInfoFragment) => void
  isTowExchange: boolean
}

export const Exchange = ({ name, searchLocation, onSetBranch, isTowExchange }: Props) => {
  const branch = useFieldValue(name)
  const callerLocation = useCallerLocation()

  return (
    <>
      {branch && !callerLocation ? (
        <ExchangeBranch name={name} />
      ) : (
        <ExchangeSearch
          name={name}
          searchLocation={searchLocation || callerLocation}
          onSetBranch={onSetBranch}
          isTowExchange={isTowExchange}
        />
      )}
    </>
  )
}

export default Exchange
