import React from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import * as Ariakit from '@ariakit/react'
import { FormApi } from 'final-form'
// @ts-ignore
import createFocusDecorator from 'final-form-focus'
import * as Yup from 'yup'
import { validateWithYup } from 'components/FormManager/utils'
import { Flex } from 'components/primitives'
import Button from 'components/Button'
import Column from 'components/Column'
import Margin from 'components/Margin'
import { Paragraph } from 'components/primitives'
import Input from 'components/Input'
import { Condition } from 'components/fields'
import { useCancelUlyDispatch } from 'hooks/urgent.ly'
import useUlyDispatchRefetch from '../useUlyDispatchRefetch'
import CancelUlyDispatchSelect from 'components/Select/CancelUlyDispatchSelect'
import { ULYCancelReasonCode } from 'types/urgently'
import { cancelDialogContext } from '../DispatchUlyService'

declare module 'types/form' {
  export interface Forms {
    cancelDispatch: FormApi
  }
}

const focusOnError = createFocusDecorator()

export function CancelUlyDispatchForm({
  caseId,
  serviceId,
  dialog,
}: {
  caseId: string
  serviceId: string
  dialog: Ariakit.DialogStore | undefined
}) {
  const { t } = useTranslation()
  const cancelUlyDispatch = useCancelUlyDispatch()
  const refetchUlyDispatch = useUlyDispatchRefetch(caseId)
  const { setSubmitted, setDispatchError, setCancelled } = cancelDialogContext.useCancelDialogContext()
  const [loading, setLoading] = React.useState(false)

  const handleSubmit = async (values: any) => {
    if (!loading) {
      setLoading(true)

      const { cancelUlyDispatchSelect, cancelNotes = '' } = values
      const cancelReason = cancelUlyDispatchSelect && cancelUlyDispatchSelect.value

      try {
        const resp = await cancelUlyDispatch({
          cancelReason,
          cancelNotes,
          caseId,
          serviceId,
        })
        await refetchUlyDispatch()
        if (resp.status !== 200) setDispatchError(true)

        setLoading(false)
        setSubmitted(true)
        setCancelled(true)
      } catch (e) {
        setLoading(false)
        setDispatchError(true)
        await refetchUlyDispatch()
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit} validate={validateWithYup(schema)} decorators={[focusOnError]}>
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <Column>
            <Margin spacing="lg">
              <Paragraph>{t('cancelDispatch.areYouSure')}</Paragraph>
            </Margin>
            <CancelUlyDispatchSelect name="cancelUlyDispatchSelect" label={t('cancelDispatch.reason')} />
            <Condition when="cancelUlyDispatchSelect" is={(v) => v?.value === ULYCancelReasonCode.Other}>
              <Input.Textarea name="cancelNotes" label={`${t('cancelDispatch.cancellationNotes')}*`} />
            </Condition>
            <Flex style={{ justifyContent: 'space-between', width: '100%' }}>
              <Button.Secondary
                onClick={() => {
                  dialog?.hide()
                  setSubmitted(false)
                  setDispatchError(false)
                }}
              >
                {t('back')}
              </Button.Secondary>
              <Button.Primary loading={loading} type="submit">
                {t('cancelDispatch.cancelService')}
              </Button.Primary>
            </Flex>
          </Column>
        </form>
      )}
    </Form>
  )
}

const schema = Yup.object().shape({
  cancelUlyDispatchSelect: Yup.object()
    .shape({
      label: Yup.string().nullable(),
      value: Yup.string().nullable().required('Required'),
    })
    .nullable()
    .required('Required'),
  cancelNotes: Yup.string().when('cancelUlyDispatchSelect', (value: any, schema: any) =>
    parseInt(value.value, 10) === ULYCancelReasonCode.Other ? schema.required() : schema,
  ),
})

export default CancelUlyDispatchForm
